.about_us_partts {
  background: linear-gradient(82.12deg, #46606f -0.59%, #17171f 99.4%);
  /* padding: 110px 0 80px 0; */
}

.about_us_partts {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.aout_left_ppartts {
  max-width: 50%;
  width: 100%;
  position: relative;
  padding-top: 50px;
  padding-bottom: 0px;
  padding-left: 100px;
}

.aout_right_ppartts {
  max-width: 50%;
  width: 100%;
}

.aout_left_ppartts:after {
  content: "";
  position: absolute;
  background: url(../../assets/images/about_grad.png) no-repeat;
  width: 812px;
  height: 858px;
  left: 0;
  top: 0;
}

.about_left_img {
  position: absolute;
  left: 100px;
  top: 160px;
  z-index: 1;
}
.about_left_imggg {
  z-index: 2;
  position: relative;
}
.about_rightt_innner_ppsrtts {
  padding-top: 104px;
  padding-right: 100px;
  padding-left: 60px;
}

.about_rightt_innner_ppsrtts .commn_heading h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.about_rightt_innner_ppsrtts p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  position: relative;
  z-index: 2;
}

.about_us_partts:after {
  content: "";
  width: 528px;
  height: 257px;
  background: linear-gradient(125.92deg, #13141b 15.99%, #303943 79.13%);
  clip-path: polygon(0 0, 100% 100%, 100% 0);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.mission {
  padding: 77px 0 112px 0;
  background: #fff;
}

.mission_top_box {
  width: 79px;
  height: 79px;
  background: #dafff9;
  border-radius: 20px 20px 20px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mission_left {
  padding-right: 40px;
}
.mission_left.mission_right {
  padding-right: 0;
}

.mission_left.mission_right .mission_top_box {
  background: #e5e1ff;
  border-radius: 20px 20px 20px 4px;
}
.mission_left h2 {
  font-weight: 600;
  font-size: 38px;
  line-height: 40px;
  text-transform: uppercase;
  color: #010101;
  margin-top: 30px;
  margin-bottom: 40px;
}

.mission_left p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #3b3d39;
  font-family: "Roboto", sans-serif !important;
}

.abt_exp_adv {
  background: linear-gradient(154.07deg, #f39ba9 1.68%, #7b40ea 100%);
  box-shadow: 0px 1px 2px rgba(166, 120, 64, 0.17);
  border-radius: 0px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.abt_exp_adv:before {
  content: "";
  position: absolute;
  width: 413px;
  height: 483px;
  background: linear-gradient(200.52deg, #fafafa 13.62%, #f1f6f9 95.2%);
  right: 0;
  top: 0;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}
.abt_exp_left {
  max-width: 40%;
  width: 100%;
}
.abt_exp_left_img {
  margin-left: -320px;
}
.abt_exp_right {
  max-width: 60%;
  width: 100%;
  background: #f5f5f5;
  border-radius: 60px 0px 0px 0px;
  padding-top: 85px;
  padding-bottom: 0px;
}
.abt_exp_right_text {
  padding-left: 200px;
  padding-right: 120px;
}
.exp_text_img {
  display: flex;
}

.abt_exp_right_text .commn_heading h2 {
  font-weight: 600;
  font-size: 38px;
  line-height: 40px;
  text-transform: uppercase;
  color: #010101;
  margin-bottom: 40px;
}

.abt_exp_right_text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #3b3d39;
  font-family: "Roboto", sans-serif !important;
}
.exp_connect {
  margin-top: 60px;
}
.exp_connect a {
  background: #febb0e;
  border-radius: 5px;
  height: 52px;
  line-height: 52px;
  padding: 0 60px;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
}

.mobile_app_1.abt_mobile_app .mobile_app_left_part {
  padding: 80px 0 0px 0;
}

@media screen and (max-width: 1400px) {
  .about_left_imggg img {
    max-width: 100%;
    height: auto;
  }
  .aout_left_ppartts:after {
    background-size: 100% 100%;
    width: 100%;
    height: 70%;
  }
  .about_rightt_innner_ppsrtts p {
    line-height: 25px;
  }
}

@media screen and (max-width: 1199px) {
  .abt_exp_left_img img {
    max-width: none;
  }
  .abt_exp_right_text {
    padding-left: 50px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 991px) {
  .aout_left_ppartts {
    padding-left: 0;
  }
  .about_rightt_innner_ppsrtts {
    padding-right: 20px;
    padding-left: 20px;
  }
  .about_rightt_innner_ppsrtts {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .about_rightt_innner_ppsrtts p {
    font-size: 14px;
  }
  .about_rightt_innner_ppsrtts .commn_heading h2 {
    font-size: 26px;
  }
  .mission {
    padding: 40px 0;
  }
  .mission_left h2,
  .abt_exp_right_text .commn_heading h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .mission_left p {
    font-size: 14px;
    line-height: 24px;
  }
  .abt_exp_right_text p {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .about_us_partts {
    flex-wrap: wrap;
  }
  .aout_left_ppartts,
  .aout_right_ppartts {
    max-width: 100%;
  }
  .aout_left_ppartts {
    padding-bottom: 0px;
    padding-top: 30px;
  }
  .mission_left.mission_right {
    padding-top: 30px;
  }
  .abt_exp_adv:before {
    width: 44%;
    height: 44%;
  }
  .abt_exp_left {
    max-width: 30%;
  }
  .abt_exp_right {
    max-width: 100%;
  }
  .exp_text_img {
    flex-wrap: wrap;
  }
  .abt_exp_left_img {
    order: 2;
    margin-left: 0px;
  }
  .abt_exp_right_text {
    padding-bottom: 40px;
  }
  .mobile_app_1.abt_mobile_app .mobile_app_left_part:before {
    width: 44%;
    height: 44%;
  }
  .about_left_img {
    left: 0;
    z-index: 1;
  }
  .aout_left_ppartts{
    order: 2;
    text-align: center;
  }

  .aout_right_ppartts{
    order: 1;
  }
}

@media screen and (max-width: 420px) {
  .abt_exp_left_img img {
    max-width: 100%;
  }
  .abt_exp_right_text {
    padding-left: 10px;
    padding-right: 10px;
  }
}
