.lead_tabss .nav.nav-tabs {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  padding: 12px 0;
}

.lead_tabss .nav.nav-tabs .nav-link {
  padding: 0px 20px;
  margin: 0 5px;
}
.lead_tabss .nav-tabs .nav-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #4e5e63;
}
.lead_tabss .nav-tabs .nav-link.active {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */
  background: transparent;
  text-align: center;
  border: 0px;
  color: #000000;
  position: relative;
}

.lead_tabss .nav-tabs .nav-link.active::after {
  content: "";
  position: absolute;
  background: #0acdfa;
  border-radius: 0px 4px 4px 0px;
  height: 3px;
  width: 100%;
  bottom: -12px;
  left: 0;
}
.lead_tabss {
  position: relative;
}

.alllead_showing_result {
  position: absolute;
  top: 10px;
  right: 20px;
}

.lead_eq_inner .refinance {
  background: #128ab0;
  border-radius: 30px;
  display: inline-block;
  padding: 3px 5px;
  width: auto;
  font-weight: 400;
  font-size: 10px;
  color: #fff3b6;
  text-transform: uppercase;
}

.tag_name ul li a {
  color: #fff;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
/* .styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  height: 18px;
  background: white;
  border: 3px solid #015063;
} */
.styled-checkbox:hover + label:before {
  background: #015063;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
  background: #015063;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.lead_eq_inner .pending_tag {
  background: #7ee7ff;
  border-radius: 30px;
  height: 16px;
  line-height: 16px;
  font-weight: 500;
  font-size: 10px;
  color: #006b84;
  display: inline-block;
  width: auto;
  padding: 0 10px;
}

.action_inner.delete {
  background: #e9330c;
}

.assign_to_but .assign_to_button {
  background: #0acdfa;
  border-radius: 5px;
  height: 44px;
  line-height: 44px;
  padding: 0 60px;
  border: 0px;
  font-weight: 700;
  font-size: 14px;
  color: #fff;
  margin-top: 40px;
}

.heading_tag {
  position: relative;
}

.any_tag_text {
  background: #d4dfe1;
  border-radius: 15px;
  height: 28px;
  line-height: 28px;
  padding: 0 5px;
  font-weight: 400;
  font-size: 16px;
  color: #475c63;
  display: inline-block;
}

.cross_sign {
  padding-left: 10px;
}

.heading_tag_text {
  position: absolute;
  top: 6px;
  left: 10px;
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.any_tag_text {
  display: flex;
  word-break: keep-all;
}
.custom_popuped.heading_popuped .tag_field_text {
  background: #eef4f7;
  border-radius: 6px;
  height: 40px;
}

.heading_tag_text2 {
  position: absolute;
  top: 10px;
  right: 10px;
}

.upload-btn-wrapper2 {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
}

.btn2 {
  background: #eef4f7;
  border-radius: 6px;
  height: 40px;
  line-height: 40px;
  width: 100%;
  display: block;
  padding: 0px 20px;
  border: 0px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #475c63;
}

.upload-btn-wrapper2 input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.comments_textarea {
  background: #eef4f7;
  border-radius: 6px;
  height: 130px;
  border: 0px;
}

.comments_textarea::placeholder {
  font-size: 14px;
}
.comments_textarea:focus {
  background: #eef4f7;
}

.select_box {
  padding: 0px 10px;
  border-radius: 6px;
  height: 40px;
  line-height: 40px;
  appearance: none;
  background: #eef4f7 url(../../../assets/images/select_arrow2.png) 98% center
    no-repeat;
  border: 0px;
  font-size: 14px;
}

.notify_section {
  padding: 30px 0 20px 50px;
}
.notify_section .form-group {
  display: block;
  margin-bottom: 15px;
}

.notify_section .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.notify_section .form-group label {
  position: relative;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  color: #475c63;
  line-height: 18px;
  margin-bottom: 0px;
}

.notify_section .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #015063;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05); */
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 25px;
}
.notify_section .form-group input:checked + label:before {
  background: #015063;
}
.notify_section .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;

  left: 6px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.status_update {
  background: #0acdfa;
  border-radius: 5px;
  height: 40px;
  padding: 0 40px;
  font-weight: 700;
  font-size: 14px;
  line-height: 40px;
  text-transform: capitalize;
  color: #ffffff;
  border: 0px;
}

.status_footer {
  display: flex;
  justify-content: flex-end;
}

.lead_resuuultts.collapse_left .lead_equipment.eqp_lead {
  width: 120%;
}

.other_details {
  background: #cde1e6;
  border-radius: 20px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lead_equipment.eqp_lead.blue_border {
  border: 1px solid #0acdfa;
}

.set_reminder_box {
  display: flex;
}

.calendar_partts,
.reminder_field {
  max-width: 50%;
  width: 100%;
  padding: 0 10px;
}
.calendar_partts {
  border-right: 1px solid #febb0e;
}
.calendar_box {
  margin-bottom: 30px;
}

.form_group1 input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form_group1 label {
  position: relative;
  cursor: pointer;
}

.form_group1 label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  width: 20px;
  height: 20px;
  /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px; */
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form_group1 input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.react-calendar__tile--active {
  background: #febb0e !important;
}
.reminder_text p {
  font-size: 17px;
  text-align: center;
  color: #000;
  margin-bottom: 2px;
}

.reminder_text p span {
  display: block;
  padding: 5px 0;
}

.react-calendar__navigation__label span {
  font-size: 13px;
}
.react-calendar__navigation button {
  min-width: 24px !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: #febb0e !important;
  min-width: 24px !important;
  color: #fff !important;
}
.reminder_text p span {
  position: relative;
  max-width: 100%;
  margin: 0px auto;
  text-align: center;
  width: 100%;
}

.reminder_text p span:before {
  content: " ";
  height: 2px;
  width: 60px;
  background: #0079bf;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.reminder_text p span:after {
  content: " ";
  height: 2px;
  width: 60px;
  background: #0079bf;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}

.reminder_textarea {
  height: 80px;
  /* border: 1px solid #0ACDFA; */
}
.calendar_box .react-calendar {
  width: 100%;
}

.reminder_text_boxx {
  /* border: 1px solid #0ACDFA; */
  height: 30px;
  padding: 0 10px;
}
.remider_content {
  margin-top: 20px;
}
.remider_content h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #000;
}

.remider_content p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #000;
}
.expert_button.reminder_but {
  display: flex;
  justify-content: flex-end;
  line-height: 30px;
}
.expert_button.reminder_but a {
  height: 30px;
  padding: 0 14px;
  line-height: 30px;
  font-size: 14px;
}
.react-time-picker__clear-button svg,
.react-time-picker__clock-button svg {
  /* fill: #febb0e; */
  stroke: #febb0e;
}

.react-time-picker__inputGroup__amPm {
  color: #febb0e;
}
@media screen and (max-width: 575px) {
  .lead_tabss .nav.nav-tabs .nav-link {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }

  .select_box_preassigneds {
    width: 100%;
  }

  .calendar_partts,
  .reminder_field {
    max-width: 100%;
  }

  .set_reminder_box {
    flex-wrap: wrap;
  }
}

.show_more {
  color: blue;
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
  cursor: pointer;
}

.select_box_preassigneds {
  border: 0px solid #0acdfa;
  font-size: 12px;
  color: #000;
  /* padding:5px 7px; */
  width: 80%;
  margin-right: 10px;
  border-radius: 10px;
}
.select_box_preassigneds .dropdown-toggle::after {
  content: none;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: transparent;
  background-color: transparent;
}

.lead_label_inner .dropdown-menu {
  border: 0px solid #0acdfa;
  border-radius: 7px;
  padding: 5px;
  box-shadow: 0 0px 8px rgba(10, 205, 250, 0.6);
  left: -50px !important;
}

.lead_label_inner .dropdown-menu .dropdown-item {
  font-size: 14px;
  border-bottom: 1px solid rgba(189, 189, 189, 0.8);
  padding: 5px 4px;
}

.lead_label_inner .dropdown-menu .dropdown-item:last-child {
  border-bottom: 0px;
}

.lead_label_inner .dropdown-menu .dropdown-item:hover {
  background: #0acdfa;
  color: #fff;
}
.select_box_preassigneds button {
  background: transparent;
  color: #000;
  border: 0px;
  font-size: 13px;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select_box_preassigneds button:hover,
.select_box_preassigneds button:focus,
.select_box_preassigneds button:active {
  background: transparent !important;
  color: #000 !important;
  width: 100%;
  text-align: left;
  border: 0px solid #0acdfa;
  box-shadow: none;
}
.select_box_preassigneds:focus {
  box-shadow: none;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
  background: transparent;
}

.react_date,
.react_date:focus,
.react_date:active {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
}
.react_date.react-datepicker-ignore-onclickoutside {
  border-color: #ced4da !important;
}

.sorting_field {
  /* width: 43px;
  height: 24px; */
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  margin: 0 10px;
}
