.me_item {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgb(116 69 45 / 10%);
  border-radius: 10px;
  position: relative;
}
.me_image {
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.me_image > a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s ease;
}
.me_info {
  padding: 15px;
}
.me_info > h2 {
  font-weight: 600;
  font-size: 16px;
  /* min-height: 3.6rem; */
}
.me_logo {
  position: absolute;
  left: 10px;
  bottom: 10px;
}
.me_logo > img {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgb(255 255 255 / 34%);
  object-fit: cover;
}
.me_action {
  position: absolute;
  z-index: 10;
  right: 0.5rem;
  top: 0.5rem;
}
.me_action .dropdown-toggle {
  background-color: rgb(0 0 0 / 63%) !important;
  border: 0;
  padding: 0;
  line-height: 1;
  width: 1.5rem;
  height: 1.5rem;
}
.me_action .dropdown-toggle:after,
.me_action .dropdown-toggle:before {
  display: none;
}
.me_action .dropdown-menu {
  border: 0;
  box-shadow: 0 0 12px hsl(202deg 12% 17% / 21%);
  border-radius: 0.6rem;
}
