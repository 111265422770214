.dashboard_breadcrumb {
  background: #ffffff;
  border: 1px solid #e1e8e8;
  border-radius: 4px;
  padding: 10px 30px;
}

.dashboard_breadcrumb a {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4e5e63;
}


.my_lead_section h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 15px;
}

.lead_right {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px;
  padding: 10px 10px;
}

.mylead_filter_heading {
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  display: flex;
  justify-content: space-between;
}

.mylead_filter_heading span {
  margin-right: 10px;
}

.lead_right .select_boxqaw {
  background: #e6eded;
  border-radius: 5px;
  height: 30px;
  border: 0px;
  font-size: 14px;
}

.select_partts {
  margin-top: 20px;
}
.select_text_field {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #969696;
  border-radius: 0px;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #8d8d8d;
}

.select_check input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.select_check .form-check {
  padding-left: 0px;
  margin: 10px 0;
}
.select_check label {
  position: relative;
  cursor: pointer;
}

.select_check label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  width: 17px;
  height: 17px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.select_check input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 5px;
  height: 11px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.select_check label span {
  background: #ffdfdf;
  border-radius: 10px;
  padding: 2px 5px;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #000;
}

.filter_text {
  position: relative;
}
.filter_text .tag_field {
  background: #e6eded;
  border-radius: 5px;
  height: 30px;
  font-weight: 400;
  font-size: 13px;
  color: #2f3939;
  border: 0px;
}

.tagsearch {
  position: absolute;
  right: 10px;
  top: 4px;
  cursor: pointer;
}

.tag_filter {
  margin-top: 20px;
}

.tag_name ul li {
  background: #ffdfdf;
  border-radius: 10px;
  height: 24px;
  list-style: none;
  display: inline-block;
  padding: 4px 6px;
  font-size: 11px;
  margin-bottom: 10px;
  margin-right: 8px;
}

.filter_text {
  margin-bottom: 10px;
  margin-top: 10px;
}
.tag_name ul li span {
  background: #7b7b7b;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  text-align: center;
  line-height: 12px;
  display: inline-block;
  color: #ffdfdf;
  font-size: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.select_partts .select_boxqaw {
  font-size: 12px;
  margin-bottom: 12px;
}

.starting_datepicker {
  border-left: 0;
  border-right: 0px;
  border-top: 0px;
  border-bottom: 1px solid #969696;
  width: 100%;
}

.starting_date {
  margin-bottom: 20px;
}
.filter_submit {
  margin-top: 40px;
}

.filter_submit .filter_submit_button {
  background: #0acdfa;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  border: 0px;
  display: block;
  width: 100%;
  padding: 0px;
}

.my_lead_heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.mylead_heading_right {
  display: flex;
  align-items: center;
}

.show_entries {
  display: flex;
  align-items: center;
}

.show_entries label {
  margin-bottom: 0px;
}

.show_field {
  width: 43px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
  margin: 0 10px;
}

.show_entries label,
.show_entries span {
  font-weight: 500;
  font-size: 14px;
  color: #000;
}
.my_lead_inner_page {
  width: 100%;
  overflow: scroll;
  height: 700px;
  margin-top: 10px;
}
.lead_eq_inner .lead_tooltip {
  display: inline-block;
  margin-left: 0px;
  margin-right: 5px;
}
.mylead_inner_inner_page {
  width: 200%;
}
.lead_equipment {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  /* background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px; */
  padding: 10px 20px;
  margin-bottom: 20px;
}
.lead_eq_inner {
  max-width: calc(100% / 10);
  width: 100%;
}

.lead_eq_inner label {
  font-weight: 400;
  font-size: 13px;
  color: #595959;
  margin-bottom: 0px;
  display: block;
}
.lead_eq_inner span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  display: block;
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.pending {
  background: #fff3b6;
  border-radius: 30px;
  padding: 0px 10px;
  font-weight: 400;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  color: #bd8800;
  text-transform: uppercase;
  display: inline-block;
  text-transform: uppercase;
}

.success {
  background: #17d758;
  border-radius: 30px;
  padding: 0px 10px;
  font-weight: 400;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  color: #05421a;
  text-transform: uppercase;
  display: inline-block;
  text-transform: uppercase;
}

.failed {
  background: #ffa386;
  border-radius: 30px;
  padding: 0px 10px;
  font-weight: 400;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  color: #511603;
  text-transform: uppercase;
  display: inline-block;
  text-transform: uppercase;
}

.tags {
  background: #7ee7ff;
  border-radius: 30px;
  height: 16px;
  padding: 0 5px;
  line-height: 16px;
  font-weight: 500;
  font-size: 10px;
  color: #006b84;
  display: inline-block;
}

.lead_arrow {
  padding-top: 15px;
  cursor: pointer;
}
.status_curr.stat_1 {
  background: #7ee7ff;
  color: #006b84;
}
.status_cross {
  display: inline-block;
  margin-left: 5px;
}
.actionsec {
  display: flex;
}
.action_inner {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.action_inner.update {
  background: #009621;
}

.action_inner.chat {
  background: #3e9eff;
}

.action_inner.feedback {
  background: #d66804;
}

.lead_showing_result {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lead_showing_result label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #777777;
  margin-bottom: 0px;
}
.lead_showing_result {
  margin-top: 30px;
  margin-bottom: 60px;
}
.result_pagination ul {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.result_pagination ul li {
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 9px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}
.result_pagination ul li a {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 15px;
  color: #000;
}

.result_pagination ul li.active {
  background: #febb0e;
  color: #fff;
}

.result_pagination ul li.disable a {
  cursor: not-allowed;
}

.my_lead_page_heading {
  background: #cbdcdb;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.my_lead_page_inner_heading {
  max-width: calc(100% / 5);
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000;
}

.mylead_inner_inner_page.my_lead_inner_content {
  /* width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px; */
}

.mylead_inner_inner_page.my_lead_inner_content .lead_eq_inner {
  max-width: calc(100% / 5);
}

.mylead_inner_inner_page.my_lead_inner_content.preassigned .lead_eq_inner {
  max-width: calc(100% / 6);
}
.lead_eq_inner .lead_label_inner {
  display: flex;
  align-items: center;
}
.lead_tabss .nav-tabs a:hover{
  border-color: transparent;
}
.lead_eq_inner .lead_label_inner .lead_arrow {
  padding-top: 0px;
  /* margin-left: 10px; */
}

.my_lead_inner_page.my_lead_inner_box {
  max-height: 600px;
  height: auto;
  overflow-y: scroll;
  overflow-x: auto;
}

/* .mylead_inner_inner_page.my_lead_inner_content .lead_equipment{
  padding: 15px 20px;
} */
.mylead_inner_inner_page.my_lead_inner_content .lead_equipment {
  margin-bottom: 0px;
}
.collapse.lead_equipment {
  padding-top: 0px;
}
.t_icon {
  transition-duration: 0.3s;
}
.t_iconhide {
  transform: rotate(90deg);
  transition-duration: 0.3s;
}

.collapse.lead_equipment.show {
  border-top: 1px solid #dfeaed;
  display: flex;
  padding: 10px 0;
}

.lead_equipment.eqp_lead {
  display: block;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px;
  margin-bottom: 10px !important;
}
.mylead_inner_inner_page.my_lead_inner_content {
  width: 100%;
}
.eqp_lead_part {
  display: flex;
  padding: 10px 0;
}

.my_lead_page_heading {
  margin-top: 30px;
}

.reset_but{
  height: 24px;
  background: #0ACDFA;
  border-radius: 5px;
  padding: 0px 10px;
  font-weight: 500;
font-size: 14px;
color: #fff;
border: 0px;
}

.close_lead, .dead_lead, .progress_lead{
  position: relative;
}

.close_lead:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #DD9624;
box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
border-radius: 15px 0px 0px 15px;
width: 8px;
height: 100%;
}

.dead_lead:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #EC6060;
box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
border-radius: 15px 0px 0px 15px;
width: 8px;
height: 100%;
}

.progress_lead:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #67DDA4;
box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
border-radius: 15px 0px 0px 15px;
width: 8px;
height: 100%;
}

.lead_eq_inner span label{
  display: inline-block;
  font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: block;
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.action-cursor{
  cursor: pointer;
}

@media screen and (max-width: 1199px) {
  .mylead_inner_inner_page {
    width: 300%;
  }
  .lead_showing_result {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .result_pagination {
    margin-top: 30px;
  }

  .lead_equipment.eqp_lead{
    width: 150%;
  }
  .lead_resuuultts.collapse_left .lead_equipment.eqp_lead{
    width: 170% !important;
  }
  .my_lead_section h2{
    font-size: 16px;
  }
  .lead_resuuultts{
    margin-top: 30px;
  }
}
@media screen and (max-width: 1199px) {
  .lead_tabss .nav-tabs .nav-link{
    font-size: 12px;
  }
  .lead_tabss .nav.nav-tabs .nav-link {
    padding: 0px 15px;
    margin: 0 5px;
}

.lead_showing_result label{
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 20px;
}
}

@media screen and (max-width: 991px) {
  .lead_eq_inner span label, .lead_eq_inner span{
    font-size: 12px;
    width: 75px;
  }
  .lead_filter{
    order: 1;
  }
  .lead_resuuultts{
    order: 2;
  }
}
@media screen and (max-width: 575px) {
  .my_lead_heading {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .dashboard_breadcrumb {
    margin: 30px 0;
  }

  .lead_resuuultts {
    margin-top: 30px;
  }
  .lead_resuuultts.collapse_left  .lead_equipment.eqp_lead{
    width: 315% !important;
  }


  .lead_resuuultts.expand_right .lead_equipment.eqp_lead{
    width: 315% !important;
  }
  .lead_equipment.eqp_lead{
    width: 250%;
  }
}

@media screen and (max-width: 420px) {
  .mylead_inner_inner_page {
    width: 350%;
  }
  .mylead_heading_right {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .add_lead_box{
    margin: 20px 0;
  }
  .add_lead {
    margin-top: 20px;
  }
  .mylead_inner_inner_page {
    width: 400%;
  }

  .lead_eq_inner span label{
    width: 75px;
  }
  .result_pagination ul{
    flex-wrap: wrap;
    justify-content: center;

  }
  .result_pagination ul li{
    margin-bottom: 20px;
  }
  .lead_eq_inner .refinance, .pending, .success{
    font-size: 10px;
  }
}
