.status_inner_partts {
  padding: 20px 20px;
  border-radius: 15px;
}

.status_tabbs a.active {
  border-left: 0px;
  border-right: 0px;
  margin-bottom: 0 !important;
  border-bottom: 4px solid #0acdfa !important;
  border-top: 0px;
  /* border-radius: 0px; */
  border-radius: 0px 4px 4px 0px !important;
  color: #000000 !important;
}

.status_tabbs a {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #4e5e63;
  padding: 5px 30px 20px;
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  margin-bottom: 0px !important;
  border-bottom: 4px solid transparent !important;
}

.added_parts {
  margin-top: 10px;
}

.added_inner_partts {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px;
  margin-bottom: 30px;
}

.added_inner_parts_heading {
  width: 100%;
  padding: 10px 0;
}
.add_heading_inner {
  padding: 0 20px;
}
.add_heading_inner span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #001b21;
  padding-right: 20px;
}

.added_inner_cont {
  padding: 20px;
}

.added_inner_heading {
  font-weight: 400;
  font-size: 16px;
  color: #000;
  width: 100%;
  line-height: 22px;
}

.feedback_comment {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  word-break: break-word;
  color: #000;
}
.feedback_tag {
  display: flex;
  flex-wrap: wrap;
}
.assign_to {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #027994;
  border-bottom: 1px solid #027994;
  display: inline-block;
}
.feedback_tag span {
  display: inline-block;
}
.status_inner_partts .doc_attached h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #4e5e63;
}
.added_tag_partts {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}
ol.doc_attached_inner li {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
  margin-right: 20px;
}
ol.doc_attached_inner {
  padding-left: 16px;
}
.tagger.yellow {
  background: #fff3b6;
}
.tagger {
  border-radius: 30px;
  padding: 6px 20px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #bd8800;
  margin-right: 20px;
  display: inline-block;
}

.tagger.blue {
  background: #626dd9;
  color: #ffffff;
}
.doc_attached h2 {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #4e5e63;
  margin-bottom: 10px;
}

.doc_attached_inner {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 30px;
}

.doc_attached_part {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 10px;
  padding: 20px 0;
  text-align: center;
  height: 100%;
  width: 120px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.doc_attached_part label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  padding-top: 25px;
  word-break: break-word;
}

.added_inner_heading.added_doc ol li {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #000000;
  margin-right: 40px;
  word-break: break-word;
}
.added_inner_heading.added_doc ol {
  display: flex;
  padding-left: 0px;
  margin-bottom: 0px;
  flex-wrap: wrap;
}
/* .tagger{
    margin-bottom: 20px;
} */

.status_change_button {
  display: flex;
  justify-content: flex-end;
}

.changes_button {
  background: #0acdfa;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  border: 0px;
  padding: 0px 25px;
  font-weight: 700;
  font-size: 14px;
  text-transform: capitalize;
  color: #ffffff;
}

.status_inner_partts h2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000;
}
.add_heading_inner {
  padding: 10px 0;
  margin: 0 20px;
  border-bottom: 1px solid #b6c2d6;
}
.add_heading_inner .feed_back_icon {
  margin-right: 10px;
  padding-left: 0px;
  padding-right: 0px;
}
.back_arrow {
  cursor: pointer;
  display: inline-block;
}
.feed_comnts h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #4e5e63;
}

.doc_title {
  cursor: pointer;
}

@media screen and (max-width: 575px) {
  .doc_attached_part {
    width: 100%;
    margin-right: 0;
  }
  .status_tabbs a {
    width: 100%;
  }
}
