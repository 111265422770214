.my_prf_left_part {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 12px 10px 0 10px;
}
.my_prf_inner ul li {
  list-style: none;
}
.my_prf_inner ul li a {
  padding: 10px 0 10px 10px;
  display: block;
  color: #015063;
  font-size: 14px;
}
.my_prf_inner ul li a img {
  display: inline-block;
}

.my_prf_inner ul li a .dropdown {
  display: inline-block;
  width: 90%;
}
.my_prf_inner ul li a span {
  padding-left: 24px;
  display: inline-block;
}

.my_prf_inner ul li a .dropdown button {
  background: none;
  border: none;
  color: #015063;
  font-size: 14px;
  padding: 0 0 0 24px;
}
.my_prf_inner ul li a .btn-success:not(:disabled):not(.disabled).active,
.my_prf_inner ul li a .btn-success:not(:disabled):not(.disabled):active,
.my_prf_inner ul li a .show > .btn-success.dropdown-toggle {
  background: none;
  border: none;
  color: #015063;
  font-size: 14px;
  box-shadow: none;
}

.my_prf_inner ul li a .btn-success.focus,
.my_prf_inner ul li a .btn-success:focus {
  box-shadow: none;
}
.my_prf_inner ul li a .dropdown-toggle {
  width: 100%;
  text-align: left;
  position: relative;
}
.my_prf_inner ul li a .dropdown-toggle::after {
  border: 0px;
  content: "";
  /* background: url(../../images/mp_down.png) no-repeat 98% center; */
  width: 10px;
  height: 7px;
  position: absolute;
  right: 20px;
  top: 7px;
}
.basic_profile {
  background: #96c3db;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 24px 36px 24px 24px;
  margin-top: 20px;
}

.basic_profile h2 {
  font-weight: 500;
  font-size: 18px;
  color: #03151f;
}

.total_prf {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.t_prf_inner,
.my_prf_social_list {
  width: 33.33%;
}

.t_prf_inner .single-chart {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.t_prf_inner .single-chart .circular-chart {
  max-width: 36%;
  margin: 0;
  max-height: 80px;
}
.t_prf_inner .single-chart .circular-chart .circle-bg {
  stroke-width: 1px;
}
.t_prf_inner .single-chart .circular-chart.orange .circle {
  stroke: #03151f;
  stroke-width: 1px;
}

.t_prf_inner .single-chart .circular-chart .percentage {
  font-weight: 600;
  font-size: 12px;
  fill: #03151f;
}

.t_prf_inner .single-chart h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  margin-left: 20px;
}

.my_prf_social_list ul {
  display: flex;
}
.my_prf_social_list ul li {
  list-style: none;
}
.my_prf_social_list ul li a {
  width: 53px;
  height: 53px;
  border-radius: 100%;
  background: #fff;
  text-align: center;
  line-height: 53px;
  display: block;
  color: #015063;
  font-size: 22px;
  margin-right: 20px;
}
.buers_inner_profile {
  background: #ffffff;
  border-radius: 10px;
  padding: 35px;
  margin-bottom:20px;
  position: relative;
}
.profile_image > img {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 1px solid #dce4e9;
}
.profile_type span {
  color: #01c3f1;
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #ecfbff;
  border-radius: 2rem;
  line-height: 1;
  font-weight: 500;
  font-size: .9rem;
}
.profile_info h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #293538;
  margin-top: 20px;
  margin-bottom: 5px;
}
.profile_info p {
  font-size: 13px;
  font-weight: 400;
  color: #515e66;
  margin: 0;
}
.buyers_right {
  position: absolute;
  top: 10px;
  right: 10px;
}
.buyers_right i {
  color: #08d12a;
  font-size: 1.1rem;
}
.profile_info {
  padding-top: 1.5rem;
}
@media screen and (max-width: 1199px) {
  .buyers_inner_right {
    width: 60%;
  }
  .buyers_right {
    position: absolute;
    top: -20px;
    right: 0;
  }
}
@media screen and (max-width: 991px) {
  .my_prf_social_list ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .t_prf_inner .single-chart h3 {
    font-size: 14px;
    line-height: 19px;
  }
}

@media screen and (max-width: 575px) {
  .total_prf {
    flex-wrap: wrap;
  }
  .t_prf_inner {
    width: 50%;
  }

  .my_prf_social_list {
    width: 100%;
  }
  .my_prf_social_list ul {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}

@media screen and (max-width: 420px) {
  .t_prf_inner {
    width: 100%;
    margin: 10px 0;
  }
  .total_prf {
    justify-content: center;
    align-items: center;
  }
}
.test_carousel.top_carousell1{
  padding-bottom: 40px;
}