.dashboard_part {
  padding: 30px 0;
}
.dash_top_part {
  display: flex;
  justify-content: space-between;
}

.dash_top_left_part {
  max-width: 25%;
  flex: 0 0 25%;
  background: url(../../assets/images/profile_bg.png) no-repeat;
  padding: 20px 15px;
  background-size: cover;
  border-radius: 1rem;
}
.dash_top_right_part {
  max-width: 75%;
  flex: 0 0 75%;
  padding-left: 1rem;
}

.prf_bg_dash {
  width: 65px;
  height: 65px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 21px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dash_user_name {
  margin-top: 30px;
}
.dash_user_name h3 {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.dash_user_name h4 {
  font-weight: 500;
  font-size: 37px;
  line-height: 1;
  text-align: center;
  color: #ffffff;
}

.prf_completed {
  display: flex;
  justify-content: space-between;
}
.prf_completed label,
.prf_completed span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
}

.dash_progressbar .progress {
  height: 6px;
  background: rgba(0, 0, 0, 0.31);
  border-radius: 21px;
}

.dash_progressbar .progress .progress-bar {
  background: #fff;
  border-radius: 21px;
}

.dash_progressbar {
  margin-top: 20px;
}
.dash_add_new {
  justify-content: space-between;
}
.dash_add_new,
.dash_add_new a {
  display: flex;
  align-items: center;
}
.dash_add_new a span {
  margin-right: 10px;
}
.dash_add_new a {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
}
.dash_add_new {
  margin-top: 10px;
}

.dashtoprightheading h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #000000;
}

.dashtoprightheading p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4e5e63;
  margin-top: 20px;
}
.ad_banner img {
  height: 120px;
  width: 100%;
  object-fit: cover;
  display: block;
}
.dash_top_right_bottom_part {
  margin: 40px -0.5rem 0;
  display: flex;
}
.dashboard_right_ad {
  max-width: 240px;
  width: 100%;
  height: 400px;
}
.dashboard_right_ad img {
  max-width: 100%;
}
.dash_inner_bottom_part {
  max-width: 25%;
  flex: 0 0 25%;
  padding: 0 0.5rem;
}
.dash_inner_bottom_inner_part {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px 15px 4px 15px;
  padding: 20px 15px;
  position: relative;
  height: 100%;
}
.dash_inner_bottom_part:nth-child(1) .dash_inner_bottom_inner_part:after {
  content: "";
  width: 16px;
  height: 17px;
  background: #f06d31;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 0px 4px 0;
}
.dash_inner_bottom_part:nth-child(2) .dash_inner_bottom_inner_part:after {
  content: "";
  width: 16px;
  height: 17px;
  background: #3fa31c;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 0px 4px 0;
}
.dash_inner_bottom_part:nth-child(3) .dash_inner_bottom_inner_part:after {
  content: "";
  width: 16px;
  height: 17px;
  background: #f63131;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 0px 4px 0;
}
.dash_inner_bottom_part:nth-child(4) .dash_inner_bottom_inner_part:after {
  content: "";
  width: 16px;
  height: 17px;
  background: #315cf6;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 0 0px 4px 0;
}
.dash_icon_box1 {
  width: 56px;
  height: 56px;
  background: #ffeee1;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 25px auto;
}
.dash_icon_box2 {
  width: 56px;
  height: 56px;
  background: #e9ffe1;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px auto;
}
.dash_icon_box3 {
  width: 56px;
  height: 56px;
  background: #ffe1e1;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px auto;
}
.dash_icon_box4 {
  width: 56px;
  height: 56px;
  background: #d9e1ff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px auto;
}
.dash_icon_box.box2 {
  background: #e9ffe1;
}
.dash_icon_box.box3 {
  background: #ffe1e1;
}
.dash_icon_box.box4 {
  background: #d9e1ff;
}
.dash_right_text h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #000000;
  margin: 0;
}

.dash_right_text label {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #595959;
  margin: 0;
}

.dash_bottom_parttsss {
  margin-top: 30px;
}

.request_history {
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 10px;
  padding: 30px 20px;
}
.request_heading_view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.request_heading_view h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.table_part_request {
  margin-top: 15px;
}

.dash_view_more a {
  max-width: 110px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #435257;
}
.dash_view_more a img {
  margin-right: 10px;
}
.request_table_top {
  height: 35px;
  width: 100%;
  background: #cbdcdb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.table_top_inner {
  max-width: 25%;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  /* line-height: 35px; */
  color: #3a3330;
}

.equipment_part_table {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(111, 74, 52, 0.12);
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}
.eq_part_table_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.req_equipment {
  max-width: 25%;
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.requser {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 20px;
}

.requser img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.req_equipment label {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 0px;
  max-width: 60%;
  width: 100%;
  /* padding: 0 10px; */
  word-break: break-word;
}

.complete {
  height: 25px;
  background: #cbffda;
  border-radius: 30px;
  padding: 0 10px;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #00982b;
}
.pending {
  height: 25px;
  background: #fff3b6;
  border-radius: 30px;
  padding: 0 10px;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #dc9e00;
}
.closed {
  height: 25px;
  background: #fff1ed;
  border-radius: 30px;
  padding: 0 10px;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #c41a1a;
}
.not-attend {
  height: 25px;
  background: #fff3b6;
  border-radius: 30px;
  padding: 0 10px;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #dc9e00;
}
.in-progress {
  height: 25px;
  background: #fff3b6;
  border-radius: 30px;
  padding: 0 10px;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
  color: #dc9e00;
}

.req_equipment.compl {
  justify-content: space-between;
}
/* .my-collapsible .my-collapsible__content{
      display: none;
  } */

.type_div {
  border-top: 1px solid #dfeaed;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.type_inner_div {
  max-width: 25%;
  width: 100%;
}

.type_inner_div label {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #595959;
  margin-bottom: 2px;
}

.type_inner_div span {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}
.dashboard_ad_partts {
  margin-bottom: 40px;
  text-align: center;
}
.dashboard_part > .container-fluid {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.dashboard_part .row {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.dashboard_part [class*="col"] {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.active {
  cursor: pointer;
}

@media (min-width: 992px) {
  .ds_left.col-lg-3 {
    flex: 0 0 21%;
    max-width: 21%;
  }
  .ds_right.col-lg-9 {
    flex: 0 0 79%;
    max-width: 79%;
  }
}
@media screen and (max-width: 991px) {
  .dash_top_left_part {
    max-width: 50%;
  }
  .dash_top_right_part {
    max-width: 46%;
  }
  .dash_add_new,
  .dash_add_new a {
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    display: block;
    margin-bottom: 20px;
  }
  .dash_inner_bottom_part {
    width: calc(100% / 1);
    margin-bottom: 20px;
  }
  .dash_top_right_bottom_part {
    flex-wrap: wrap;
  }
  .table_part_request {
    overflow-x: auto;
    width: 100%;
  }
  .request_table_top,
  .equipment_part_table {
    width: 250%;
  }

  .dashboard_ad_partts {
    height: auto;
  }

  .dashboard_right_ad {
    margin: 30px 0;
  }
}
@media screen and (max-width: 768px) {
  .req_equipment label {
    max-width: 60%;
    width: 100%;
    word-break: break-word;
  }
  .dashboard_ad_partts {
    height: auto;
  }
}
@media screen and (max-width: 576px) {
  .dash_top_part {
    flex-wrap: wrap;
  }
  .dash_top_left_part {
    max-width: 100%;
  }
  .dash_top_right_part {
    max-width: 100%;
    margin-top: 30px;
  }
  .dashboard_right_panel {
    margin-top: 40px;
  }
  .table_part_request {
    overflow-x: auto;
    width: 100%;
  }
  .request_table_top,
  .equipment_part_table {
    width: 250%;
  }
  .req_equipment label {
    max-width: 60%;
    width: 100%;
    word-break: break-word;
  }
}
@media screen and (max-width: 480px) {
  .dash_inner_bottom_part {
    width: calc(100% / 1);
    margin-bottom: 20px;
  }
}
