.sponsor_box_left.jobseeker_left {
  width: 230px;
  height: 230px;
}

.sponsor_box_right.jobseeker_right {
  max-width: 68%;
}

.paver_left h5 {
  font-size: 16px;
  line-height: 19px;
  color: #015063;
  margin-bottom: 10px;
}
.sponsor_box_left.jobseeker_left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.select_heading.education_details + .checkbox_category {
  display: block;
  height: 100%;
  opacity: 1;
  transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
}

.exp {
  cursor: pointer !important;
}

@media screen and (max-width: 991px) {
  .sponsor_box_left.jobseeker_left {
    width: 180px;
    height: 180px;
  }

  .sponsor_box_right.jobseeker_right {
    max-width: 50%;
  }
  .sponsor_box_right h2,
  .sponsor_box_right h2 a {
    font-size: 24px;
    line-height: 32px;
  }
}

@media screen and (max-width: 480px) {
  .sponsor_box_left.jobseeker_left {
    width: 100%;
    height: auto;
  }
  .sponsor_box_right.jobseeker_right {
    max-width: 100%;
  }
}

.js_img {
  flex: 0 0 150px;
  max-width: 150px;
}
.js_img img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 0.6rem;
}
.js_title {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #30373c;
  margin-bottom: 0.5rem;
}
.js_address {
  font-size: 13px;
  line-height: 1.2;
  color: #595959;
}

.js_expertise .list-inline-item {
  font-size: 0.8rem;
  border: 1px solid #e1e7eb;
  padding: 0.3rem 0.5rem;
  line-height: 1;
  border-radius: 2rem;
  color: #47535a;
  margin-bottom: 0.4rem;
}
.js_expertise h5 {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.2;
  color: #595959;
  margin-bottom: 0.3rem;
}
.js_review {
  border: 1px solid #e1e8ed;
  border-radius: 4px;
  line-height: 1;
  font-weight: 500;
  font-size: 12px;
  color: #4e575c;
  padding: 0.3rem 0.5rem;
}
.js_review .fa-star {
  color: #ff9a1e;
}
.js_address .fa-map-marker {
  color: #0acdfa;
}
