.slider_carousel1 {
  display: flex;
  flex-wrap: wrap;
}

.slider_car_left {
  max-width: 43%;
  width: 100%;
  position: relative;
}

.slider_carousel1 {
  background: linear-gradient(238.43deg, #fafdff 10.24%, #deedf5 96.4%);
}
.slider_car_right {
  max-width: 57%;
  width: 100%;
  position: relative;
  z-index: 7;
}

.slider_top_img {
  position: absolute;
  right: -11px;
  top: -5px;
}

.slider_top_img img {
  width: 98%;
}

.slider_graphics {
  position: absolute;
  bottom: 0;
  right: -200px;
  z-index: 2;
}
.slider_carousel1 {
  position: relative;
}
.tetx_cont h1 {
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 60px;
  text-transform: uppercase;
  color: #0acdfa;
}

.tetx_cont .banner_cont h1 .equipment {
  color: #0f0f0f;
}
.tetx_cont h2 {
  font-weight: 600;
  font-size: 3.125rem;
  line-height: 60px;
  text-transform: uppercase;
  color: #0acdfa;
}
.rented_machine_inner_top span {
  display: inline !important;
}

.tetx_cont {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  right: auto;
  bottom: auto;
  text-align: left;
  transform: translateY(-50%);
}

.banner_cont {
  max-width: 400px;
  width: 100%;
}
.banner_cont h1 span {
  color: #0f0f0f;
}

.banner_cont p {
  margin-top: 37px;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 26px;
  color: #1f2b2e;
  padding-right: 40px;
}

.banner_cont .banner_contact {
  height: 48px;
  background: #febb0e;
  box-shadow: 9px 13px 20px rgba(181, 130, 0, 0.28);
  border-radius: 5px;
  padding: 0 40px;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  text-transform: uppercase;
  color: #ffffff;
  display: inline-block;
  margin-top: 40px;
}
.submit_form_home.commn_button {
  margin-top: 40px;
}
.skew_bg {
  position: absolute;
  width: 295px;
  height: 648px;
  background: linear-gradient(195.89deg, #ebf5fb 2.55%, #d2dde2 101.05%);
  clip-path: polygon(0 0, 100% 0, 0 100%, 0% 100%);
  left: 0;
  top: -3px;
  /* position: relative; */
  z-index: 1;
}

.countiong {
  margin-top: -100px;
}
.submit_button {
  height: 48px;
  background: #febb0e;
  border-radius: 5px;
  line-height: 48px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0 50px;
  border: 0px;
  display: inline-block;
}

.banner_inner_part {
  /* width: calc(100%/5); */
  padding: 0 8px;
  position: relative;
}
.counter_topsvg {
  position: absolute;
  top: -13px;
  left: 0;
}
.countercont {
  position: relative;
}
.counter_bottomsvg {
  position: absolute;
  bottom: -13px;
  left: 0;
}

.slider__banner_bottom {
  padding-top: 30px;
  padding-bottom: 60px;
}

.banner_bottom_part .react-multi-carousel-dot-list {
  bottom: 20px;
}

.banner_bottom_part .react-multi-carousel-dot-list button {
  width: 16px;
  height: 16px;
  border-color: #febb0e;
}

.banner_bottom_part
  .react-multi-carousel-dot.react-multi-carousel-dot--active
  button {
  background: #febb0e;
}
.banner_inner_inner {
  background: #fff;
  position: relative;
  padding-top: 30px;
  padding-bottom: 27px;
  padding-left: 32px;
  padding-right: 32px;
}
.banner_slider1 .carousel-control-prev,
.banner_slider1 .carousel-control-next {
  display: none;
}
/* .banner_inner_inner:after{
    border-radius: 100%;
    content: "";
    position: absolute;
    top: 0;
} */
.banner_slider1 .react-multi-carousel-dot-list {
  height: 123px;
  width: 3px;
  background: rgba(0, 0, 0, 0.4);
  display: block;
  right: 60px;
  left: auto;
  bottom: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  margin: 0;
}

.banner_slider1 .react-multi-carousel-dot-list:before {
  content: "01";
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Roboto", sans-serif !important;
  color: #000000;
  left: -20px;
}

.banner_slider1 .react-multi-carousel-dot-list:after {
  content: "04";
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "Roboto", sans-serif !important;
  color: #000000;
  left: -20px;
  top: 105px;
}
.banner_slider1 .react-multi-carousel-dot-list li button {
  flex: none;
  width: 3px !important;
  height: 29px !important;
  margin: 0px;
  border: 0;
  background: transparent;
}

.banner_slider1
  .react-multi-carousel-dot-list
  li.react-multi-carousel-dot--active
  button {
  background: #000000;
  border-radius: 0px;
}

.counter_img {
  padding-bottom: 48px;
  margin-bottom: 24px;
  position: relative;
}
.counter_img:after {
  content: "";
  width: 29px;
  height: 2px;
  background: #b9cad6;
  position: absolute;
  bottom: 0;
  left: 0;
}

.banner_inner_inner h3 {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  color: #78878b;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 20px;
}

.banner_inner_inner .counter_texttts {
  font-weight: 600;
  font-size: 2.813rem;
  line-height: 45px;
  color: #1f2b2e;
}

.market_place {
  margin-top: 170px;
}
.abt_heading {
  max-width: 520px;
  width: 100%;
  height: 198px;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 0) 60.42%,
    #f5f5f5 100%
  );
  padding-top: 55px;
}
.abt_heading h2 {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-size: 100px;
  line-height: 95px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #cfd7da;
}
.videobg {
  position: absolute;
  left: -57px;
  top: 28px;
  width: 100%;
}

.about_comp_players {
  margin-left: -30px;
  position: relative;
  margin-right: -20px;
}
.videoplayerbg .react-player__preview {
  background-size: 100% 100% !important;
}
.mobile_app_buton .qrCode img {
  width: 135px;
  height: 135px;
}
.videobg .react-player__preview .play_button {
  width: 75px;
  height: 75px;
  background: #febb0e;
  border-radius: 100%;
  border: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -55px;
}

.videobg .react-player__preview .play_button::after {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  content: "";
  width: 75px;
  height: 75px;
  border: 24px solid rgba(254, 187, 14, 0.6);
  border-radius: 100%;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}
.videobg .react-player__preview .play_button::before {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0;
  content: "";
  width: 75px;
  height: 75px;
  border: 24px solid rgba(254, 187, 14, 0.6);
  border-radius: 100%;
  -webkit-animation-name: ripple;
  animation-name: ripple;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}

@-webkit-keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}

.commn_heading h2 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 40px;
  text-transform: uppercase;
  color: #010101;
  position: relative;
  padding-top: 30px;
  margin-bottom: 22px;
}
.commn_heading h2:before {
  content: "";
  width: 70px;
  height: 3px;
  background: #febb0e;
  position: absolute;
  left: 0;
  top: 0;
}

.player_text p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;
  color: #3b3d39;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 40px;
}

.learnmore_but a {
  height: 48px;
  line-height: 48px;
  background: #febb0e;
  border-radius: 5px;
  padding: 0 45px;
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
}

.features_part {
  padding: 90px 0 40px 0;
  background: linear-gradient(82.12deg, #17171f -0.59%, #46606f 99.4%);
  position: relative;
  z-index: 1;
}
.features_part:after {
  content: "";
  position: absolute;
  width: 528px;
  height: 331px;
  background: linear-gradient(195.89deg, #303943 2.55%, #13141b 101.05%);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  left: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
}
.features_left_partss {
  padding-top: 107px;
}

.features_left_partss .commn_heading h2 {
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 40px;
  z-index: 3;
}

.features_left_partss .commn_heading h2:before {
  background: #fff;
  z-index: 3;
}

.features_left_partss p {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 40px;
}

section.features_part .features_item {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
}
.features_right_partts > img {
  position: absolute;
  top: -3rem;
}
.features_inner_item {
  padding: 0 10px;
  width: calc(100% / 4);
  margin-bottom: 95px;
}

.features_inner_inner {
  background: #181c24;
  box-shadow: 0px 3px 0px #0e1116;
  border-radius: 0 0 10px 10px;
  padding: 25px 10px 25px 10px;
  width: 100%;
  position: relative;
  height: 100%;
  margin-top: 4rem;
}
.features_inner_inner .features_top_svg {
  position: absolute;
  top: -42px;
  left: 0;
}
.features_top_icon {
  background: #181c24;
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 4px 15px 15px 15px;
  position: absolute;
  top: -62px;
  left: 20px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.features_top_icon.color1 {
  border-color: #fd2c2c;
}
.features_top_icon.color2 {
  border-color: #eebf44;
}
.features_top_icon.color3 {
  border-color: #99ee44;
}
.features_top_icon.color4 {
  border-color: #44ee9d;
}
.features_top_icon.color5 {
  border-color: #4474ee;
}
.features_top_icon.color6 {
  border-color: #b02fff;
}
.features_top_icon.color7 {
  border-color: #ed1898;
}
.features_top_icon.color8 {
  border-color: #0f92f1;
}
.features_contts h2 {
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 18px;
}

.features_contts p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
}

.expert_advice2 {
  padding-top: 55px;
  padding-bottom: 28px;
  position: relative;
}
.blue_particles img {
  margin-right: 120px;
}
.expert_man {
  position: absolute;
  right: 0;
  top: 0;
}

.expert_left .commn_heading h2 {
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 45px;
  text-transform: uppercase;
  color: #000000;
  margin-top: 50px;
}
.expert_left .commn_heading h5 {
  font-size: 2.375rem;
  line-height: 45px;
  color: #f0ae02;
  max-width: 470px;
  width: 100%;
  text-transform: capitalize;
  font-weight: 600;
}
.expert_left p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 28px;
  color: #000000;
  font-family: "Roboto", sans-serif !important;
  padding-right: 15px;
  margin-top: 40px;
}
.expert_button {
  margin-top: 80px;
}
.expert_button a {
  height: 52px;
  line-height: 52px;
  background: #febb0e;
  border-radius: 5px;
  display: inline-block;
  padding: 0 50px;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif !important;
  color: #ffffff;
}

.services_left img {
  width: 100%;
  height: 100%;
}
.our_services {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.services_left {
  max-width: 50%;
  width: 100%;
  flex: 0 0 auto;
}
.services_right {
  background: linear-gradient(82.12deg, #17171f -0.59%, #46606f 99.4%);
  flex: 0 0 auto;
  max-width: 50%;
  width: 100%;
  padding: 50px 80px 50px 50px;
  position: relative;
}
.sevices_skew_img {
  background: linear-gradient(169.17deg, #1a222b 6.32%, #415664 75.16%);
  width: 400px;
  height: 331px;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.services_right .commn_heading h2 {
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  max-width: 260px;
  width: 100%;
}

.services_right .commn_heading h2:before {
  background: #eef3f9;
}

.services_right p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;
  font-family: "Roboto", sans-serif !important;
  color: #ffffff;
  position: relative;
  z-index: 2;
}
.services_learn_more {
  margin-top: 40px;
  position: relative;
  z-index: 2;
}
.services_learn_more a {
  height: 52px;
  line-height: 52px;
  background: #febb0e;
  border-radius: 5px;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
  padding: 0 50px;
}

.services_heading {
  position: absolute;
  bottom: 0;
  left: 70px;
  z-index: 1;
}

.our_projects {
  padding: 90px 0 75px 0;
}

.prj_left .commn_heading h2 {
  font-size: 2.375rem;
}

.prj_left p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;
  color: #3b3d39;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 40px;
}
.prj_right {
  position: relative;
  /* margin-right: -150px; */
}
.prj_heading {
  position: absolute;
  top: 0;
  left: 200px;
  z-index: 1;
}

.prj_right_img {
  position: relative;
  z-index: 4;
}

.our_career {
  background: #17171f;
}

.career_image {
  margin-top: -60px;
}

.career_right_text {
  padding: 80px 0 45px 0;
}

.career_right_text .commn_heading h2 {
  color: #ffffff;
  font-size: 2.375rem;
}

.career_right_text .commn_heading h2:before {
  background: #fff;
}

.career_right_text p {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;
  color: #ffffff;
  font-family: "Roboto", sans-serif !important;
}

.mobile_app_1 {
  background: linear-gradient(178.82deg, #0ef0fe 1.01%, #febb0e 129.02%);
  /* padding: 100px 0 200px 0; */
}

.mobile_app_left_part {
  z-index: 1;
  position: relative;
  padding: 80px 0 50px 0;
}
.mobile_app_left_part:after {
  content: "";
  position: absolute;
  max-width: 70%;
  width: 100%;
  background: #f5f5f5;
  border-radius: 0px 60px 0px 0px;
  /*  */
  left: 0;
  z-index: -1;
  top: 0;
  height: 100%;
}
.mobile_app_left_part:before {
  content: "";
  width: 447px;
  height: 523px;
  background: linear-gradient(200.52deg, #fafafa 13.62%, #f1f6f9 95.2%);
  -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  position: absolute;
  left: 0;
  top: 0;
}

.mobile_app_text {
  display: flex;
  justify-content: space-between;
}

.mobile_app_left_text {
  max-width: 447px;
  width: 100%;
  /* margin-left: 128px; */
  position: relative;
}

.mobile_app_left_text .commn_heading h2 {
  font-size: 2.375rem;
  color: #010101;
  max-width: 350px;
  width: 100%;
}

.mobile_app_left_text .commn_heading h2 span {
  display: block;
}
.mobile_upper,
.mobile_lower {
  text-align: center;
}

.qrCode {
  margin-bottom: 20px;
}
.mobile_app_left_text p {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;
  color: #3b3d39;
  position: relative;
  z-index: 99;
  /* padding-right: 60px; */
  margin-top: 30px;
}
.mobile_upper {
  margin-bottom: 20px;
  /* margin-right: 40px; */
}
.mobile_app_buton {
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  position: relative;
  z-index: 3;
}
.mobile_upper,
.mobile_lower {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
}
.mobile_app_right_text {
  max-width: 700px;
  width: 100%;
  padding: 0px;
  margin: 0px;
  height: 100%;
}
.mobile_app_right_text .slide {
  width: 100%;
  height: 100%;
}

.mobile_app_right_text .carousel-indicators {
  bottom: -35px;
}
.mobile_app_right_text .carousel-indicators li {
  width: 7px;
  height: 5px;
  background: rgba(78, 94, 99, 0.66);
  border-radius: 35px;
  transition: all 0.3s ease-in-out;
  border: 0px;
}
.mobile_app_right_text .carousel-indicators li.active {
  width: 16px;
  height: 5px;
  background: #0acdfa;
  border-radius: 35px;
  transition: all 0.3s ease-in-out;
  border: 0px;
}

.mobile_app_text .mobile_app_heading {
  position: absolute;
  left: 0;
  bottom: 0px;
  margin-left: -137px;
}

/******************Our Finance start************************/
.our_finance {
  background-image: url(../../assets/images/finance_banner.png);
  background-size: cover;
  background-position: center top;
  padding: 85px 0;
}

.finance_right_text {
  background: rgba(255, 255, 255, 0.92);
  border-radius: 20px 20px 20px 0px;
  width: 100%;
  padding: 60px 40px;
}
.finance_right_text .commn_heading h2 {
  font-weight: 600;
  font-size: 2.375rem;
  line-height: 40px;
  text-transform: uppercase;
  color: #010101;
  padding-top: 20px;
  margin-bottom: 10px;
}

.finance_right_text .commn_heading h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #010101;
  font-family: "Roboto", sans-serif !important;
}

.finance_right_text p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 30px;
  color: #3b3d39;
  margin-top: 20px;
  font-family: "Roboto", sans-serif !important;
}
/******************Our Finance end************************/
.reuest_listteing li {
  list-style: none;
  margin-right: 14px;
  line-height: 18px;
}
.reuest_listteing li:last-child {
  margin-right: 0px;
}
.reuest_listteing {
  display: flex;
  flex-wrap: wrap;
}
.reuest_listteing li span {
  font-size: 13px;
  line-height: 14px;
}
.reuest_listteing li label input {
  margin-right: 5px;
  position: relative;
  top: 2px;
}
.expert_left {
  position: relative;
}

.expert_left:after {
  content: "";
  background: url(../../assets/images/curve.png) no-repeat;
  width: 342px;
  height: 110px;
  position: absolute;
  right: -210px;
  top: 0;
}

.machine_type {
  padding: 50px 0;
  background: linear-gradient(to bottom, rgb(226 250 255) 0%, #f3fdff00 100%);
}
.top_rented_machine {
  background: linear-gradient(to bottom, rgb(255 255 243) 0%, #f3fdff00 100%);
  padding: 50px 0;
}
.machine_heading h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000;
  margin-bottom: 0px;
}
.top_rented_machine .machine_heading h2 {
  margin-bottom: 0px;
}
.machine_type .machine_heading h2 {
  text-align: center;
}

.machine_listing {
  margin: 0 5px;
  height: 100%;
  display: block;
  position: relative;
  z-index: 3;
}
.machine_inner_partts.machine_type_inner_partts {
  width: 100%;
  height: 100%;
  padding: 10px;
}
.machine_inner_partts.machine_type_inner_partts img {
  width: 65px;
  height: 65px;
  object-fit: contain;
  border-radius: 50%;
  background-color: #f2f4f5;
}
.rented_machine_inner {
  padding: 0 10px;
}
.machine_inner_partts {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 2px rgb(116 69 45 / 10%);
}
.rented_grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: 25px;
}

.rented_grid .rented_machine_inner {
  max-width: calc(100% / 4);
  width: 100%;
  margin-bottom: 20px;
  flex-flow: column;
}
.machine_inner_partts label {
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #4e5e63;
  margin-top: 10px;
  margin-bottom: 0px;
  position: relative;
  z-index: 1;
  cursor: inherit;
}
.total_machine_count {
  margin: 10px 0 0 0;
  color: #000;
  font-size: 13px;
}
.total_machine_count span {
  font-weight: 600;
  display: inline-block;
  margin-left: 10px;
}

.machine_inner_partts.machine_type_inner_partts .total_machine_count img {
  width: auto;
  height: auto;
}
.carousel-container {
  padding-bottom: 60px;
  padding-top: 40px;
}

.custom-dot-list-style li button {
  width: 7px;
  height: 5px;
  background: rgba(78, 94, 99, 0.66);
  border-radius: 35px;
  border: 0px;
  transition: all 0.3s ease-in-out;
}

.custom-dot-list-style li.react-multi-carousel-dot--active button {
  width: 16px;
  height: 5px;
  background: #0acdfa;
  border-radius: 35px;
  border: 0px;
  transition: all 0.3s ease-in-out;
}

.rented_machine_inner_inner {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px;
}
.rented_machine_inner_top {
  height: 238px;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}
.rented_machine_inner:hover .rented_machine_inner_top img {
  transform: scale(1.04);
}

.eye_show {
  display: inline-block;
  height: 22px;
  background: #ffffff;
  border-radius: 15px;
  padding: 0 10px;
  line-height: 22px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  /* identical to box height, or 86% */
  color: #4e5e63;
}

.eye_show img {
  width: auto !important;
  height: auto !important;
  margin-right: 4px;
}
.rented_machine_inner_top img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.rented_machine_inner_bottom {
  padding: 20px 15px;
}

.rented_machine_inner_bottom h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rented_machine_inner_bottom h2,
.rented_machine_inner_bottom h2 a {
  color: #000000;
}

.rented_machine_inner_bottom h2 a:hover {
  color: #0acdfa;
}

.year_brand {
  display: flex;
}

.year_brand > span {
  color: #3b3d39;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  margin-right: 20px;
}

.year_brand > span label {
  font-weight: 400;
  margin-right: 7px;
  margin-bottom: 0px;
}

.rented_lcation label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #3b3d39;
  margin-bottom: 5px;
  display: block;
}
.rented_lcation span {
  font-size: 13px;
  color: #738285;
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cust_arrow .arrow.left {
  right: 40px;
  top: 0px;
  left: auto;
}
.cust_arrow .arrow.right {
  right: 0px;
  top: 0px;
  left: auto;
}
.arrow {
  background: #ffffff !important;
  border-radius: 9px;
  width: 32px;
  height: 32px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.arrow:hover {
  background: #0acdfa !important;
  transition: all 0.3s ease-in-out;
}

.arrow:hover img {
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
  transition: all 0.3s ease-in-out;
}

.brad_part .machine_listing .machine_inner_partts {
  height: auto;
}

.brad_part .machine_listing .machine_inner_partts.brand_partts img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.mobile_app_right_text {
  /* background: #000; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 420px;
  margin-top: 60px;
}

.carousel-inner {
  height: 100%;
  border-radius: 20px;
}
.carousel-item {
  overflow: hidden;
  border-radius: 20px;
}
.carousel-item > div {
  height: 390px !important;
  border-radius: 20px;
}

.read_more_equipment {
  display: flex;
  justify-content: flex-end;
}

.read_more_equipment a:hover {
  text-decoration: none;
  color: #fff;
}
@media screen and (max-width: 1440px) {
  .prj_heading {
    left: 50px;
  }
  .prj_right {
    margin-right: 0px;
  }
}
@media screen and (max-width: 1410px) {
  .prj_heading {
    left: 50px;
  }
  .prj_right {
    margin-right: 0px;
  }
  .about_comp_players {
    margin-left: 0px;
    position: relative;
    margin-right: 0px;
  }
  .mobile_app_right_text {
    padding-left: 100px;
  }
  .submit_form_home.commn_button {
    margin-top: 10px;
  }
  .banner_cont p {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1280px) {
  .videobg {
    left: 0;
  }
  .mobile_app_right_text {
    padding-left: 0px;
  }
}
@media screen and (max-width: 1199px) {
  .skew_bg {
    height: 400px;
  }
  .tetx_cont h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .tetx_cont h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .banner_cont p {
    margin-top: 20px;
    font-size: 16px;
    padding-right: 55px;
  }
  .banner_cont .banner_contact {
    margin-top: 30px;
  }
  .countiong {
    margin-top: -50px;
  }
  .counter_topsvg {
    top: -10px;
  }
  .videobg {
    left: 0;
  }
  .abt_heading {
    max-width: 100%;
    padding-top: 30px;
  }
  .features_inner_inner .features_top_svg {
    top: -35px;
  }
  .services_right {
    padding: 30px;
  }
  .services_right .commn_heading h2,
  .services_right p {
    position: relative;
    z-index: 99;
  }
  .sevices_skew_img {
    width: 256px;
    height: 256px;
  }
  .features_part:after {
    width: 345px;
    height: 180px;
  }
  .career_right_text {
    padding: 30px 0;
  }
  .career_right_text p {
    font-size: 15px;
    line-height: 24px;
  }
  .mobile_app_buton {
    margin-top: 30px;
    position: relative;
    z-index: 99;
  }
  .mobile_app_left_part {
    max-width: 100%;
  }
  .mobile_app_left_text {
    max-width: 47%;
    margin-left: 0;
  }
  .mobile_app_right_text {
    max-width: 47%;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 991px) {
  .banner_bottom_part {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .banner_inner_part {
    z-index: 99;
    margin-bottom: 50px;
  }
  .counter_topsvg {
    width: 100%;
    left: 0;
  }

  .counter_topsvg path {
    width: 100%;
  }
  .market_place {
    margin-top: 60px;
  }
  .features_inner_item {
    width: calc(100% / 3);
  }
  .about_comp_players {
    margin-left: 0;
    position: relative;
    margin-right: 0;
  }
  .abt_heading h2 {
    font-size: 50px;
    line-height: 60px;
  }
  .videobg > div {
    height: 300px !important;
  }
  .counter_bottomsvg {
    bottom: -10px;
  }
  .features_inner_inner .features_top_svg {
    top: -30px;
  }
  .services_left img {
    object-fit: cover;
  }

  .home_text_field {
    margin-bottom: 20px;
  }
  .mobile_app_buton .qrCode img {
    height: auto;
  }

  .foter_play a {
    margin-bottom: 20px;
    display: block;
  }

  .banner_inner_inner .counter_texttts {
    font-size: 32px;
  }
  .banner_inner_part {
    margin-bottom: 25px;
  }

  .banner_cont p {
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
  }
  .slider__banner img {
    height: 500px;
    object-fit: cover;
  }

  .career_image {
    margin-top: 0px;
  }
  .mobile_app_left_part {
    max-width: 100%;
  }
  .mobile_app_left_text {
    max-width: 47%;
    margin-left: 0px;
  }

  .mobile_app_right_text {
    max-width: 47%;
  }
}

@media screen and (max-width: 767px) {
  .slider__banner img {
    height: 490px;
    object-fit: cover;
  }
  .banner_inner_inner .counter_texttts {
    font-size: 35px;
  }
  .about_comp_players {
    margin-left: 0px;
    margin-right: 0;
  }
  .player_text {
    margin-top: 340px;
  }
  .features_left_partss {
    padding-top: 0px;
  }

  .expert_lfttr {
    order: 2;
  }

  .expert_righttr {
    order: 1;
  }
  .services_left,
  .services_right {
    max-width: 100%;
  }
  .our_services .sevices_skew_img {
    display: none;
  }
  .services_right .commn_heading h2 {
    max-width: 100%;
  }
  .mobile_app_text {
    flex-wrap: wrap;
  }
  .player_text {
    margin-top: 200px;
  }
  .mobile_app_right_text {
    padding-left: 0px;
    position: relative;
    z-index: 9;
    margin-right: 0px;
  }
  .mobile_app_left_part {
    max-width: 100%;
  }

  .our_finance {
    padding: 40px 0;
  }
  .finance_right_text {
    padding: 30px 20px;
  }

  .banner_cont {
    max-width: 90%;
  }
  .banner_slider1 .react-multi-carousel-dot-list {
    top: 75%;
    right: 60px;
  }

  .commn_heading h2,
  .features_left_partss .commn_heading h2,
  .expert_left .commn_heading h2,
  .services_right .commn_heading h2,
  .prj_left .commn_heading h2,
  .career_right_text .commn_heading h2,
  .finance_right_text .commn_heading h2,
  .mobile_app_left_text .commn_heading h2 {
    font-size: 28px;
    line-height: 34px;
  }
  .finance_right_text .commn_heading h3 {
    font-size: 18px;
  }
  .player_text p {
    font-size: 14px;
    margin-bottom: 25px;
    line-height: 28px;
  }
  .features_part {
    margin-top: 30px;
    padding: 60px 0 0 0;
  }
  .features_left_partss p {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .expert_left p {
    padding-right: 0px;
    font-size: 14px;
    margin-top: 20px;
  }
  .expert_button {
    margin-top: 30px;
  }
  .services_right p {
    font-size: 14px;
    line-height: 28px;
  }
  .our_projects {
    padding: 40px 0;
  }

  .prj_left p {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 25px;
  }
  .career_right_text p {
    font-size: 14px;
    line-height: 28px;
  }
  .finance_right_text p,
  .mobile_app_left_text p {
    font-size: 14px;
    line-height: 28px;
  }
  .mobile_app_left_part {
    padding-top: 50px;
  }
  .banner_cont p {
    font-size: 14px;
  }
  .expert_left:after {
    content: none;
  }
  /* .new_customnav{
  margin: 10px 0 0 0;
} */

  .rented_grid .rented_machine_inner {
    max-width: calc(100% / 3);
  }

  .mobile_app_left_part:before {
    width: 50%;
  }
}

@media screen and (max-width: 575px) {
  .career_image {
    display: none;
  }
  .videobg {
    z-index: 999;
  }
  .banner_slider1 .react-multi-carousel-dot-list {
    top: 75%;
    right: 60px;
  }
  .mobile_app_buton {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .mobile_app_left_part:before {
    width: 60%;
  }
  .finance_right_text p {
    font-size: 14px;
    line-height: 24px;
  }
  .rented_grid .rented_machine_inner {
    max-width: calc(100% / 2);
  }

  .mobile_app_left_text,
  .mobile_app_right_text {
    max-width: 100%;
  }

  .mobile_upper,
  .mobile_lower {
    margin: 0 20px;
  }
}

@media screen and (max-width: 480px) {
  .banner_cont p {
    padding: 10px;
    background: rgba(255, 255, 255, 0.9);
  }
  .banner_slider1 .carousel-indicators {
    top: 70%;
  }
  .abt_heading h2 {
    font-size: 60px;
    line-height: 70px;
  }
  .skew_bg {
    height: 180px;
    width: 175px;
  }
  .features_inner_item {
    width: calc(100% / 2);
  }
  .expert_left p {
    padding-right: 0px;
    margin-top: 20px;
  }
  .prj_heading {
    left: 0;
  }
  .mobile_app_left_part:before {
    width: 340px;
    height: 200px;
  }

  .videobg > div {
    height: 200px !important;
  }
  .player_text {
    margin-top: 85px;
  }
  .banner_inner_inner {
    background: #fff;
    position: relative;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .counter_topsvg {
    top: -18px;
  }
  .counter_bottomsvg {
    bottom: -18px;
  }
}

@media screen and (max-width: 420px) {
  .banner_inner_inner .counter_texttts {
    font-size: 28px;
  }
  .mobile_upper {
    margin-right: 0px;
  }
  .commn_heading h2,
  .features_left_partss .commn_heading h2,
  .expert_left .commn_heading h2,
  .services_right .commn_heading h2,
  .prj_left .commn_heading h2,
  .career_right_text .commn_heading h2,
  .mobile_app_left_text .commn_heading h2 {
    font-size: 28px;
  }
  .player_text p,
  .features_left_partss p,
  .expert_left p,
  .services_right p,
  .prj_left p,
  .career_right_text p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
    position: relative;
    z-index: 3;
  }
  .features_part {
    margin-top: 60px;
  }
  .features_part:after {
    width: 245px;
  }
  .learnmore_but a,
  .expert_button a,
  .services_learn_more a,
  .features_left_partss .commn_heading h2 {
    margin-bottom: 20px;
  }
  .expert_button {
    margin-top: 40px;
  }
  .our_projects {
    padding: 30px 0;
  }
  .mobile_app_left_part:before {
    width: 226px;
    height: 200px;
  }
  .mobile_app_left_text {
    margin-left: 0;
  }
  .mobile_app_left_part {
    padding: 30px 0 60px 0;
  }
  .mobile_app_left_text p {
    font-size: 14px;
    padding-right: 0;
  }

  .mobile_app_buton .mr-5 {
    margin-right: 20px !important;
  }

  .counter_bottomsvg {
    bottom: -10px;
  }
  .features_inner_inner .features_top_svg {
    top: -29px;
  }

  .finance_right_text .commn_heading h2 {
    font-size: 28px;
  }

  .rented_grid .rented_machine_inner {
    max-width: calc(100% / 1);
  }

  .mobile_app_left_part:after {
    max-width: 90%;
  }

  .mobile_upper,
  .mobile_lower {
    margin: 20px 0px;
  }
}

.sub_category_label {
  margin-bottom: 0 !important;
  font-weight: 600;
}
.sub_category_check {
  margin-left: 15px;
}

.form_center {
  max-width: 70%;
  width: 100%;
  margin: 0 auto;
}
.machine_type .react-multiple-carousel__arrow--left,
.sb_car .react-multiple-carousel__arrow--left {
  left: 0.5rem;
}
.machine_type .react-multiple-carousel__arrow--right,
.sb_car .react-multiple-carousel__arrow--right {
  right: 0.5rem;
}

.seo_area {
  padding: 3rem 0;
}
.seo_cms h1 {
  font-size: 1.2rem;
}
.seo_cms p,
.seo_cms ol li,
.seo_cms ul li {
  font-size: 0.8rem;
  line-height: 1.4;
  color: #747c81;
}
.seo_cms ul,
.seo_cms ol {
  padding-left: 2rem;
}
.seo_cms ul li + li,
.seo_cms ol li + li {
  margin-top: 0.8rem;
}

.seo_faqs .ant-collapse-item {
  border: 0;
  border-radius: 0.5rem;
  overflow: hidden;
}
.seo_faqs .ant-collapse,
.seo_faqs .ant-collapse-item .ant-collapse-content {
  background-color: transparent;
  border: 0;
}
.seo_faqs .ant-collapse-item .ant-collapse-content-box {
  padding-top: 0;
  padding-left: 2.4rem;
  font-size: 0.9rem;
  line-height: 1.4;
  color: #747c81;
}
.seo_faqs .ant-collapse-item.ant-collapse-item-active .ant-collapse-header {
  color: #3a444b;
}
.seo_faqs .ant-collapse-item .ant-collapse-header {
  font-size: 1.1rem;
  font-weight: 700;
  color: #868e95;
  align-items: center;
}

.lazy-load-image-background {
  width: 100% !important;
  display: block;
}
