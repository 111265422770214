.prf_heading {
  display: flex;
  justify-content: space-between;
}
.prf_holder {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
  background: #ffffff;
}
.delete_button {
  width: 32px;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 5px;
  text-align: center;
  line-height: 32px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -47px;
}
.pu_area {
  position: relative;
  top: -4rem;
}
.prf_holder > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.delete_button a {
  color: #027994;
  font-size: 17px;
}
.custom_file {
  background: #ffffff;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 99;
  width: 40px;
  height: 40px;
  margin: 0;
  cursor: pointer;
  box-shadow: 0 0 7px rgb(44 55 62 / 25%);
  text-align: center;
  line-height: 40px;
  font-size: 1.2rem;
  color: #446464;
  transition: all 0.3s ease;
}
.custom_file:hover {
  background-color: #13cbf1;
  color: #fff;
}
.custom_file .custom-file-input {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
.pe_form .form-group > label {
  font-size: 0.9rem;
  color: #687781;
  line-height: 1;
}
.pe_form .text_box,
.phonenumber_field {
  min-height: 46px;
  border: 1px solid #bfcfd3;
  padding: 0.375rem 0.75rem;
  border-radius: 0.4rem;
  width: 100%;
}
.add_document {
  margin-top: 1.8rem;
}
.pe_form {
  width: 900px;
  max-width: 100%;
  margin: 6rem auto 0;
  background-color: #fff;
  padding: 0 2rem 2rem;
  border-radius: 0.6rem;
}
.PhoneInputInput {
  border: 0;
  background-color: transparent;
  outline: none !important;
}
.pe_form .text_box::placeholder {
  color: #000;
  font-size: 16px;
}
.name_user {
  position: absolute;
  bottom: 13px;
  left: 20px;
  z-index: 99;
}
.group_partts .form-group > label {
  font-size: 1rem;
  line-height: 1;
}
.ad_right {
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 99;
}

.custom_file1 {
  height: 50px;
  line-height: 50px;
}
.pe_form .custom_file1.text_box {
  line-height: 37px;
}
.custom_file1 .custom-file-input {
  height: 50px;
  line-height: 50px;
}
.informa_tion i {
  color: #ffbf27;
}
.informa_tion {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #027994;
}

.btn_group {
  margin-top: 40px;
}
/* new */
.my_adhar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.my_adhar .adhar_span {
  display: flex;
  justify-content: space-between;
  background: #cff6ff;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}

/************modal start**************/
.prf2_modal_header {
  border-bottom: 0px;
  text-align: center;
  background: #d9e5e8;
  border-radius: 5px;
  display: block;
  position: relative;
}

.prf2_modal .modal-dialog .modal-content {
  padding: 10px 20px;
}

.prf2_modal_header h2 {
  text-align: center;
  color: #027994;
  font-weight: 600;
  font-size: 18px;
}

.modal_arrow {
  position: absolute;
  left: 20px;
  top: 14px;
}
.modal_add_more .add_more_inner {
  background: #027994;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
}
.modal_add_more .add_more_inner img {
  padding-right: 20px;
}
.modal_add_more {
  display: flex;
  justify-content: flex-end;
}
.inner_doc {
  width: 90%;
  margin: 0 auto;
}

.inner_doc h3 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
}
