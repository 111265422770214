.update_lead {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
}

.update_lead h2 {
  font-weight: 500;
  font-size: 26px;
  color: #000;
  margin-bottom: 20px;
}

.phone_field121 {
  background: #e6eded;
  border-radius: 5px;
  height: 40px;
  padding: 0 10px;
}

.phone_field121 .PhoneInputInput {
  border: 0px;
  background: #e6eded;
}

.phone_field121 .PhoneInputInput:focus {
  border: 0px;
  outline: none;
}

.cust_update_lead .update_field {
  background: #e6eded;
  border-radius: 5px;
  height: 40px;
  width: 100%;
}

.cust_update_lead select {
  height: 40px;
  background: #e6eded;
  border-radius: 5px;
  appearance: none;
  background: #e6eded url(../../../assets/images/select_arrow2.png) no-repeat
    98% center;
}

.cust_update_lead select:focus {
  background: #e6eded url(../../../assets/images/select_arrow2.png) no-repeat
    98% center;
}

.cust_update_lead .lead_text_area {
  height: 143px;
  background: #e6eded;
  border-radius: 5px;
}

.upload-btn-wrapper1 {
  position: relative;
  overflow: hidden;
}

.upload-btn-wrapper1 .btn {
  height: 40px;
  width: 100%;
  display: block;
  background: #e6eded;
  border-radius: 5px;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  color: #2f3939;
  text-align: left;
}
.upload-btn-wrapper1 .btn:before {
  content: "";
  background: url(../../../assets/images/upload_icon.png) no-repeat 98% center;
  position: absolute;
  right: 10px;
  top: 6px;
  width: 24px;
  height: 24px;
}
.upload-btn-wrapper1 input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.lead_upload_img {
  padding-left: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
}

.lead_upload_img li {
  list-style-type: none;
  max-width: calc(100% / 5);
  width: 100%;
}
.upl_img {
  width: 37px;
  height: 40px;
  margin: 0 auto;
}

.upl_img img {
  width: 100%;
  height: 100%;
}

.uptext {
  text-align: center;
}

.uptext h2 {
  font-weight: 500;
  font-size: 14px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 5px;
}
.uptext label {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #595959;
}

.eqp_name_fild .eqp_lead_icon {
  top: 8px;
}

.alternate_phone_add {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}

.submit_lead.update_lead_but {
  display: flex;
  justify-content: space-between;
}

.edit_radio_group {
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.image_box img,
.image_box video,
.image_box iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image_box img {
  width: 100%;
  height: 70px;
  border-radius: 0.6rem;
  object-fit: cover;
}
.image_cross {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_box .image_cross img {
  width: auto;
  height: auto;
}
.image_box .video_play img {
  width: auto;
  height: auto;
}
.image_box .video_play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cert_box,
.upload_image_box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.2rem;
  margin-right: -0.2rem;
  align-items: center;
}
.cert_box_inner,
.image_box,
.upload_box,
.cert_box_upload {
  max-width: 25%;
  flex: 0 0 25%;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.cret_file {
  padding: 25px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1px 2px rgb(116 69 45 / 10%);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.cret_file:before {
  content: "";
  background: rgba(13, 43, 50, 0.11);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform: scale(0);
  transition: all 0.3s ease;
}

.click_drop .dropdown button {
  background: transparent;
  border-color: transparent;
}
.click_drop .dropdown button:focus {
  box-shadow: none;
}
.click_drop .dropdown button:after {
  content: none;
}

.click_drop .dropdown-menu {
  background: #ffffff;
  box-shadow: 3px 3px 42px rgba(64, 50, 43, 0.15);
  border-radius: 10px;
  width: 155px;
  height: auto;
  border: 0px;
}
.click_drop .btn-success:not(:disabled):not(.disabled).active,
.click_drop .btn-success:not(:disabled):not(.disabled):active,
.click_drop .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.click_drop {
  display: none;
  position: absolute;
  right: 5px;
  top: 5px;
}

.cert_box_inner:hover .click_drop {
  display: block;
}
.click_drop .dropdown-item {
  padding: 10px 10px;
  font-weight: 400;
  font-size: 14px;
  color: #2a3234;
}
.click_drop .dropdown-item.drop_item {
  color: #ff1414;
}
.click_drop .dropdown-item span {
  display: inline-block;
  width: 32px;
}
.cert_box_inner:hover .cret_file:before {
  transform: scale(1);
}
.cert_box_inner label {
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.2;
  margin-top: 0.4rem;
  margin-bottom: 0;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.upload_btn_wrappererer {
  overflow: hidden;
  position: relative;
  z-index: 9;
}
.upload_btn_wrappererer .btnner {
  width: 100%;
  height: 70px;
  background: #f6faf9;
  border: 1.5px dashed #86a0a0;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_btn_wrappererer input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 8;
  cursor: pointer;
  /* width: 0; */
  width: 100%;
  height: 100%;
}
.cust_update_lead .date_picker {
  background: #e6eded;
  border-radius: 5px;
  height: 40px;
}

.ins_status {
  display: flex;
  align-items: center;
}

.ins_sta_left {
  max-width: 40%;
  width: 100%;
}

.ins_sta_right {
  max-width: 60%;
  width: 100%;
}

.cust_update_lead .css-1t8y0t9-control,
.cust_update_lead .css-1s2u09g-control {
  height: 46px;
  background: #eef4f7;
  border-radius: 6px;
  border: 0px;
  margin-bottom: 2px;
}

.search_frm_list {
  background: #e6eded;
  border-radius: 5px;
  height: 40px;
  padding: 0 10px;
}

.search_form_part {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 3px;
  border-radius: 6px;
  /* background: #f0efee; */
}

.search_frm_list,
.search_man_year {
  max-width: 44%;
  width: 100%;
  line-height: 40px;
}

.plus_parttts {
  max-width: 4%;
  width: 100%;
  line-height: 40px;
  cursor: pointer;
}
