.thank_you_partts{
    max-width: 800px;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 0 20px rgb(181 130 0 / 40%);
    padding: 30px;
    margin: 60px auto;
    text-align: center;
    background: #fff;
}

.thank_right h3{
    font-weight: 600;
    font-size: 50px;
    line-height: 70px;
    /* text-transform: uppercase; */
    color: #febb0e;
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
    font-family: "Poppins", sans-serif !important;
}

.thank_right p{
    color: #000;
    font-size: 22px;
    margin: 0px;
    font-weight: 600;
    margin-top:20px;
    font-family: "Poppins", sans-serif !important;
}

.back_to_home{
    margin-top: 50px;
}

.back_to_home a{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0acdfa;
    font-size: 16px;
}
.back_to_home a img{
    margin-right: 10px;
}