.my_prf_inner ul li a.activemenu {
  background: #dcecf0;
  border-radius: 5px;
}
.basic_profile {
  position: relative;
}

.my_prf_social_list {
  position: absolute;
  top: 10px;
  right: 10px;
  width: auto !important;
}

.byuers_add_prf .buyers_add_inner_prf {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #015063;
  display: inline-block;
  /* float: right; */
  padding: 0 30px;
  margin-bottom: 20px;
}

.my_prf2_details {
  background: #f1f1f1;
  border-radius: 5px;
  padding: 20px 45px 30px 45px;
}

.my_prf2_details h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #027994;
}

.my_prf2_inner_pr {
  width: 100px;
  height: 100px;
  background: #98e1f1;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.my_prf2_inner_pr .flex_button {
  right: -30px;
  bottom: 25px;
}
.request_left h2 {
  font-weight: 600;
  font-size: 24px;
  /* line-height: 46px; */
  color: #014050;
}

.request_left p {
  font-size: 16px;
  line-height: 24px;
  color: #8b8e8e;
  padding-top: 8px;
  padding-bottom: 7px;
}

.available {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  padding-top: 8px;
  /* padding-bottom: 15px; */
}
.available.color1 {
  color: #eb7f1e;
}
.available.color2 {
  color: #0ab864;
}

.available.color3 {
  color: #047fd8;
}

.available.color4 {
  color: #ff4b12;
}

.available.color5 {
  color: #000000;
}
.request_date {
  color: #8b8e8e;
}
.chhoose_fielddd h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 30px;
}
.delete_request.btn {
  color: #f22;
  border-radius: 0.5rem;
}
.delete_request.btn:hover {
  background-color: #fff5f5;
}
.rf_item,
.rfi_label {
  display: block;
}
.rf_item {
  padding: 10px;
  border: 1px solid #e2e7eb;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 0.3s ease;
  line-height: 1;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.rf_item input[type="radio"] {
  display: none;
}
.rfi_label:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid #03464a;
  border-radius: 0.6rem;
  transform: scale(0);
}
.rf_item input[type="radio"]:checked ~ .rfi_label:after {
  transform: scale(1);
}
.rfi_label {
  margin-left: 0.5rem;
}

.parts_search_icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.specify_req {
  margin: 15px 0;
}
.reqf_item h5 {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.reqf_item {
  margin-bottom: 2rem;
}
.reqf_item > h5 {
  font-size: 1.05rem;
  margin-bottom: 0.4rem;
}
.request_item.card {
  background-image: linear-gradient(to bottom, white 50%, transparent 100%);
  background-color: transparent;
  border: 0;
}
.request_item .card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 1rem;
}
.request_item .card-title {
  color: #2e3840;
  font-size: 1.1rem;
}

.select_heading.job_details + .checkbox_category {
  display: block;
  height: 100%;
  opacity: 1;
  transition: height 300ms ease-in-out, opacity 300ms ease-in-out;
}
.or-separator {
  margin: 20px 0;
  text-align: center;
}
.or-separator span {
  position: relative;
  display: inline-block;
}
.or-separator span:after,
.or-separator span:before {
  content: "";
  position: absolute;
  width: 60px;
  height: 1px;
  background-color: #d4dde3;
  top: 50%;
  margin-top: -1px;
}
.or-separator span:after {
  left: 100%;
  margin-left: 0.5rem;
}
.or-separator span:before {
  right: 100%;
  margin-right: 0.5rem;
}

@media screen and (max-width: 1199px) {
  .buyers_inner_right {
    width: 60%;
  }
  .buyers_right {
    position: absolute;
    top: -20px;
    right: 0;
  }
}
@media screen and (max-width: 991px) {
  .my_prf_social_list ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .t_prf_inner .single-chart h3 {
    font-size: 14px;
    line-height: 19px;
  }
  .choose_inner_fields {
    width: calc(100% / 2);
    padding: 0 15px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 575px) {
  .total_prf {
    flex-wrap: wrap;
  }
  .t_prf_inner {
    width: 50%;
  }

  .my_prf_social_list {
    width: 100%;
  }
  .my_prf_social_list ul {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}
@media screen and (max-width: 480px) {
  .request_left h2 {
    font-size: 16px;
  }
  .request_left {
    max-width: 100%;
  }
  .request_right {
    max-width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 420px) {
  .t_prf_inner {
    width: 100%;
    margin: 10px 0;
  }
  .total_prf {
    justify-content: center;
    align-items: center;
  }
  .choose_inner_fields {
    width: calc(100% / 1);
  }
}
