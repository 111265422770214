.time_line_inner h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.time_line_inner_inner ul {
  padding-left: 8px;
  margin-bottom: 0px;
  position: relative;
  margin-top: -13px;
  z-index: 1;
}
.time_line_inner_inner .ul_top {
  width: 18px;
  height: 18px;
  /* background: rgba(10, 205, 250, 0.14); */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: relative;
  z-index: 3;
}

.time_line_inner_inner .ul_top_inner {
  width: 10px;
  height: 10px;
  background: #0acdfa;
  border: 2px solid #0acdfa;
  border-radius: 100%;
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
  box-shadow: 0 0 0 rgb(10 205 250 / 4%);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(10, 205, 250, 4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(10, 205, 250, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(10, 205, 250, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(10, 205, 250, 4);
    box-shadow: 0 0 0 0 rgba(10, 205, 250, 4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(10, 205, 250, 0);
    box-shadow: 0 0 0 10px rgba(10, 205, 250, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(10, 205, 250, 0);
    box-shadow: 0 0 0 0 rgba(10, 205, 250, 0);
  }
}

/* .time_line_inner_inner ul:before {
  content: "";
    position: absolute;
    width: 2px;
    height: 72%;
    background: #CBD8DC;
    top: 16px;
    left: 8px;
} */

.time_line_inner_inner ul li {
  list-style: none;
  border-left: 2px solid #cbd8dc;
  padding-left: 45px;
}

.time_line_inner_inner ul li label {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #4e5e63;
  margin-bottom: 0px;
  display: block;
}
.time_line_inner_inner ul li:last-child::before {
  display: none;
}
.time_line_inner_inner ul li label span {
  margin-left: 10px;
  display: inline-block;
  color: #58696e;
  border: 1px solid #aec0c5;
  padding: 0.2rem 1rem;
  border-radius: 1rem;
}
.time_line_inner_inner ul li {
  padding-bottom: 45px;
}
.adddwed_doc {
  background: #eff2f3;
  /* box-shadow: 0px 1px 2px rgb(116 69 45 / 10%); */
  border-radius: 10px;
  padding: 10px 15px;
  margin-top: 5px;
  display: inline-block;
}

.adddwed_doc h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 0px;
}

.adddwed_doc h2 span {
  font-weight: 600;
}

.doc_partts {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.innnner_doc {
  background: #ffffff;
  border: 1px solid #86a0a0;
  border-radius: 5px;
  padding: 3px 15px;
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #4e5e63;
  margin-right: 12px;
  margin-bottom: 12px;
}
.image_preview img {
  width: 10rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 0.6rem;
}
.doc_preview img {
  width: 4rem;
}
.doc_view_inner {
  font-size: 0.8rem;
  color: #009dc1;
}
.lead_info {
  background-color: #f5fcfd;
  border-radius: 0.9rem;
  padding: 1.5rem;
}
.time_prev {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.time_line_inner_inner {
  margin-top: 20px;
}
.li_item .badge {
  border: 1px solid #0acdfa;
  padding: 0.2rem 1rem;
  margin-top: 0.2rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  color: #0acdfa;
}
.li_item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.li_item dl {
  margin: 0;
}
.li_item dd {
  font-size: 1.1rem;
  font-weight: 600;
  color: #293538;
  margin: 0;
}
.time_prev h2 {
  margin-bottom: 0px;
}
.back_arrowsw {
  margin-right: 20px;
  cursor: pointer;
}
.commnet_parttts {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2f3939;
  margin-top: 15px;
}

.kyc_updated {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #4e5e63;
  position: relative;
  padding-left: 40px;
  margin-top: 20px;
}

.kyc_updated:before {
  content: "";
  position: absolute;
  background: url(../../../assets/images/kyc_curve.png) no-repeat;
  width: 22px;
  height: 17px;
  left: 0;
  top: 0;
}
.time_line_inner_inner ul li {
  position: relative;
}
.time_line_inner_inner ul li:last-child {
  border-left: 0px;
  padding-left: 0;
  margin-top: 0.5rem;
  padding-bottom: 0;
}
.time_line_inner_inner ul li:last-child .adddwed_doc {
  background: #fff;
  padding: 0;
}
.time_line_inner_inner ul li:first-child:before {
  content: none;
}
.time_line_inner_inner ul li:before {
  content: "";
  position: absolute;
  background: url(../../../assets/images/libefore.png) no-repeat;
  width: 30px;
  height: 12px;
  left: -2px;
  top: 0;
}

.kyc_verify {
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #4e5e63;
}

.kyc_verify span {
  color: #2f3939;
}

.adddwed_doc.dailyreport {
  background: #e3e8ff;
}

.verify_done span {
  display: inline-block;
  /* padding: 10px 20px; */
  font-weight: 400;
  font-size: 14px;
  color: #2f3939;
}

.kyc_read_more a {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #040404;
  position: relative;
  z-index: 9;
}

.adddwed_doc.added_lead {
  background: #dbfff2;
}
.adddwed_doc.logged_in {
  background: #dbe9ff;
}
.adddwed_doc.disbrusment {
  background: #fff7e5;
}
.adddwed_doc.commision_paid {
  background: #f0eeff;
}
.adddwed_doc.dead_or_lost {
  background: #ffeeee;
}

.added_tag_partts {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0px;
}
.tagger {
  border-radius: 30px;
  padding: 5px 15px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #bd8800;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.assign_to {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #df4242;
  border-bottom: 1px solid #f5a3e4;
  display: inline-block;
  margin-top: 10px;
}
.cust {
  margin-right: 20px;
}
.cust img {
  margin-left: 10px;
}
.verify_done {
  display: flex;
  padding: 10px 0;
}

.time_line_inner_inner .extra_field ul {
  margin: 0px;
  padding: 0px;
  border: 0px;
  position: static;
  display: flex;
  flex-wrap: wrap;
}
.extra_field {
  padding: 20px 0;
}
.time_line_inner_inner .extra_field ul li {
  padding: 5px 10px;
  margin: 0px 15px 10px 0;
  font-weight: 600;
  border: 1px solid #bdbdbd;
  border-radius: 7px;
  font-size: 15px;
}

.time_line_inner_inner .extra_field ul li span {
  font-weight: 400;
  display: inline-block;
  margin-left: 10px;
}
.time_line_inner_inner .extra_field ul li:before {
  content: none;
}

.time_line_edit {
  display: flex;
  justify-content: space-between;
}
.edit_btn_timeline {
  padding: 2px 10px;
  line-height: 1;
  border: 1px solid #293538;
  background-color: transparent;
  color: #293538;
  border-radius: 14px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.3s ease;
}
.edit_btn_timeline:hover {
  background-color: #2b373a;
  color: #fff;
}
.lcu_image img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.lcu_name h5 {
  font-size: 1.1rem;
  font-weight: 600;
}
.lcu_image {
  flex: 0 0 41px;
  max-width: 41px;
}
.lcu_name {
  flex: 1 1;
  padding-left: 0.5rem;
}
.el_modal .form-group > .form-label {
  font-size: 1rem;
  font-weight: 600;
}
.others_filed {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 6px;
}

.others_filed span {
  font-weight: 500;
  display: inline-block;
  margin-right: 10px;
  color: #e85a3a;
}
.time_line_inner {
  background: #fff;
  box-shadow: 0px 1px 2px rgb(116 69 45 / 10%);
  border-radius: 10px;
  padding: 20px;
}
.li_item dt {
  font-size: 0.85rem;
  color: #465152;
  font-weight: normal;
}
