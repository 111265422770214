.rent_result_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 2rem;
}

.rent_result_top h2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4e5e63;
  margin-bottom: 0px;
}

.buy_search_box {
  max-width: 380px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_textbox {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 36px;
  border: 0px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  color: #6e838a;
}

.search_box_rent {
  position: relative;
}
.eqp_search_icon {
  position: absolute;
  right: 10px;
  top: 5px;
}
.buy_rent_viewbox {
  display: flex;
  max-width: 85px;
  width: 100%;
  justify-content: space-between;
}

.buy_rent_viewbox span.active_color img {
  -webkit-filter: invert(50%); /* Safari/Chrome */
  filter: invert(50%);
}

.rent_result_bottom {
  margin-top: 50px;
}

.result_inner {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  background: #fff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px;
  margin-bottom: 20px;
}

.result_inner_left {
  flex: 0 0 180px;
  max-width: 180px;
  position: relative;
}
.result_inner_right {
  width: 24%;
  max-width: 100%;
}
.result_inner_middle {
  flex: 1 1;
  padding: 0 10px;
}
.result_inner_left span img {
  height: 150px;
  border-radius: 10px;
  width: 100%;
  /* object-fit: cover; */
}

.result_inner_left span {
  display: inline !important;
}
.machine__type {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #0090ff;
  padding: 0.2rem 0.5rem;
  line-height: 1;
  font-size: 0.8rem;
  border-radius: 0 0.5rem;
  color: #fff;
  z-index: 1;
}
.sbc_card {
  height: 100%;
  cursor: pointer;
  padding: 5px;
}
.sbc_card_label {
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  color: #4e5e63;
  margin-bottom: 0px;
  margin-top: 10px;
}
.sbc_card img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.sbc_card .checkmark {
  position: absolute;
  inset: 0 0 0 0;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  pointer-events: none;
}
.sbc_card input:checked ~ .checkmark {
  border-color: #0acdfa;
}
.avalaibility {
  margin-top: 10px;
}

.avalaibility h4 {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #595959;
}

.result_inner_middle h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.avalaibility p {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
  margin-top: 6px;
  margin-bottom: 0;
  word-break: break-word;
}

.result_posted {
  display: flex;
  justify-content: space-between;
}

.result_postd_iner {
  width: 33%;
  max-width: 100%;
  padding-right: 5px;
}

.result_postd_iner h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #595959;
}

.result_postd_iner p {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  margin: 0;
}

.result_posted {
  margin-top: 20px;
}

.result_inner_right {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  padding: 10px;
}

.result_top_inner {
  display: flex;
  justify-content: space-between;
}

.result_user_image {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  overflow: hidden;
  background: #f3f3f3;
  border: 1px solid #9dadab;
}

.result_user_image img {
  width: 100%;
  height: 100%;
}

.result_star {
  width: 50px;
  height: 25px;
  border: 1px solid #c6d6d6;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  line-height: 25px;
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  padding: 5px;
  justify-content: space-between;
}

.result_inner_right h5 {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 0px;
}

.result_inner_location span {
  margin-left: 4px;
  font-weight: 400;
  font-size: 11px;
  color: #595959;
}

.result_inner_location .fa-map-marker {
  color: #0acdfa;
}
.connect_now_butinner {
  margin-top: 20px;
}
.connect_now_butinner a {
  background: #0acdfa;
  border-radius: 5px;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #ffffff;
  display: inline-block;
}

.rent_result_bottom.grid_view {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rent_result_bottom.grid_view .result_inner {
  max-width: 48%;
  width: 100%;
  display: block;
}

.rent_result_bottom.grid_view .result_inner .result_inner_left,
.rent_result_bottom.grid_view .result_inner .result_inner_middle,
.rent_result_bottom.grid_view .result_inner .result_inner_right {
  max-width: 100%;
  width: 100%;
}
.rent_result_bottom.grid_view .result_inner .result_inner_middle {
  margin: 20px 0;
}

.rent_result_bottom.grid_view
  .result_inner
  .result_inner_right
  .connect_now_butinner
  a {
  display: block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.filter_partts {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(116, 69, 45, 0.1);
  border-radius: 15px;
  padding: 10px;
}

.filter_heading span {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-left: 10px;
}
.buy_rent_viewbox span {
  cursor: pointer;
}
.eqp_added {
  margin-top: 25px;
}

.eqp_added h3 {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #2f3939;
}

.eqp_added_radiopart [type="radio"]:checked,
.eqp_added_radiopart [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.eqp_added_radiopart [type="radio"]:checked + label,
.eqp_added_radiopart [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #595959;
  margin-bottom: 5px;
}
.eqp_added_radiopart [type="radio"]:checked + label:before,
.eqp_added_radiopart [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 14px;
  border: 2px solid #0acdfa;
  border-radius: 100%;
  background: #fff;
}
.eqp_added_radiopart [type="radio"]:checked + label:after,
.eqp_added_radiopart [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 6px;
  height: 6px;
  background: #0acdfa;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.eqp_added_radiopart [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.eqp_added_radiopart [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.eqp_added_radiopart {
  margin-top: 10px;
}

.eqp_search_for {
  margin-top: 20px;
}
.eqp_search_for p {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #2f3939;
}

.rent_resale a {
  background: #ffffff;
  border: 1.5px solid #b3c8cd;
  box-sizing: border-box;
  border-radius: 5px;
  height: 30px;
  padding: 0 10px;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  color: #595959;
}
.rent_resale {
  display: flex;
  flex-wrap: wrap;
}
.rent_resale a span {
  width: 14px;
  height: 14px;
  background: #e6eded;
  border-radius: 100%;
  margin-right: 10px;
  display: inline-block;
}

.rent_resale a.active {
  border-color: #0acdfa;
}
.eqp_search_for p {
  margin-bottom: 10px;
}
.rent_resale a.active span {
  background: #0acdfa;
}
.select_box_rent {
  margin-top: 20px;
}
.select_box_rent .select_inner_box {
  height: 30px;
  background: #e6eded url(../../assets/images/select_down.png) 96% center
    no-repeat;
  border-radius: 5px;
  padding: 0px 10px;
  border: 0px;
  appearance: none;
  font-weight: 400;
  font-size: 13px;
  color: #2f3939;
  margin-bottom: 15px;
}

.range_rent_slider label {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #2f3939;
  display: block;
  margin-bottom: 5px;
}

.hmr_value {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #595959;
  margin-bottom: 10px;
}

.user_rating_rent {
  margin: 30px 0 30px;
}
.user_rating_rent h4 {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #2f3939;
}

.submit_button_rent button {
  height: 30px;
  line-height: 30px;
  background: #0acdfa;
  border-radius: 5px;
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #ffffff;
  padding: 0;
  border: 0;
}

.react-multi-carousel-list.eq_slider {
  border-radius: 0.5rem;
}
.eq_slider .react-multi-carousel-dot button,
.rl_product_slider .react-multi-carousel-dot button {
  width: 0.5rem;
  border: 0;
  height: 0.3rem;
  border-radius: 0.2rem;
}
.eq_slider .react-multi-carousel-dot button {
  background-color: rgb(255 255 255 / 65%);
}
.rl_product_slider .react-multi-carousel-dot button {
  background-color: rgba(78, 94, 99, 0.65);
}
.eq_slider .react-multi-carousel-dot--active button {
  width: 1rem;
  background-color: #fff;
}
.rl_product_slider .react-multi-carousel-dot--active button {
  background-color: rgba(78, 94, 99, 1);
  width: 1rem;
}
.spf_icon {
  flex: 28px;
  max-width: 28px;
}
.spf_text {
  flex: 1 1;
  padding-left: 0.6rem;
}
.sp_head {
  position: relative;
}
.spf_text dl,
.spf_text dl dd {
  margin: 0;
}
.spf_text dl dd,
.spf_text dl dt {
  font-size: 0.9rem;
}
.spf_text dl dd {
  color: #4e5e63;
}
.spf_text dl dt {
  font-weight: 600;
}
.sp_features {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  align-items: center;
}
.sp_text {
  font-size: 0.9rem;
  color: #4e5e63;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}
.dashboard_part .sp_info {
  padding-left: 2rem;
}
.sp_model {
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
}
.cs_item {
  height: 15rem;
}
.cs_item > img {
  pointer-events: none;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.seller_pd .rounded-circle {
  width: 4.8rem;
  height: 4.8rem;
  border: 1px solid #e6e6e6;
}
.seller_name {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0;
}
.seller_location,
.mo_item p {
  font-size: 0.9rem;
  color: #4e5e63;
}
.seller_location {
  margin-bottom: 0.5rem;
}
.seller_rating,
.machines_own {
  margin-bottom: 1rem;
}
.seller_rating img {
  margin: 0 0.1rem;
}
.mo_item h4 {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1;
}

.rl_product_slider .cs_item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.rl_prods_img {
  overflow: hidden;
  height: 220px;
  border-radius: 0.8rem;
  margin-bottom: 1rem;
}
.rl_prods_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
.rl_prods_data > h2 {
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
}
.rl_prods_data > h2,
.rl_prods_data > h2 a {
  color: #000;
}
.rl_specs_item {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.rl_product_slider {
  padding-bottom: 3rem;
}
.spq_img img {
  margin-bottom: -1.25rem;
}
.sp_company {
  color: #4e5e63;
  font-size: 0.9rem;
}

.rent_resale div {
  background: #ffffff;
  border: 1.5px solid #b3c8cd;
  box-sizing: border-box;
  border-radius: 5px;
  height: 30px;
  padding: 0 10px;
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 30px;
  color: #595959;
  cursor: pointer;
  margin: 0 0.2rem 0.2rem;
}
.rent_resale div span {
  width: 14px;
  height: 14px;
  background: #e6eded;
  border-radius: 100%;
  margin-right: 10px;
  display: inline-block;
}

.rent_resale div.active {
  border-color: #0acdfa;
}

.rent_resale div.active span {
  background: #0acdfa;
}
.spc_wrap {
  border-radius: 0.6rem;
  margin: 0.5rem 0;
  background-image: linear-gradient(to bottom, #e0f7fd, #b7dbe4);
  overflow: hidden;
}
.spc_card {
  padding: 0.5rem;
  background-repeat: no-repeat !important;
  height: 8.5rem;
  background-position: bottom right !important;
}
.spb_logo {
  position: absolute;
  right: 0.8rem;
  top: 0.5rem;
  z-index: 1;
}
.spb_logo > img {
  width: 40px;
  height: 40px;
  box-shadow: 0 0 0 3px rgb(0 0 0 / 12%);
  object-fit: cover;
}

.spc_card h4 {
  font-size: 0.85rem;
}
.spc_card p {
  /* font-size: 1.2rem;
  margin-top: 0.3rem; */
  font-weight: 600;
}

.box_item {
  height: auto;
  border: 1px solid #9a9595;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  margin-right: 10px;
  margin-bottom: 10px;
}
.rl_products .react-multi-carousel-track {
  padding: 20px 20px;
}

@media screen and (max-width: 991px) {
  .rent_resale {
    flex-wrap: wrap;
  }
  .rent_resale div {
    width: 100%;
    margin-bottom: 20px;
  }

  .result_inner {
    flex-wrap: wrap;
  }
  .result_inner_left,
  .result_inner_middle,
  .result_inner_right {
    width: 100%;
  }
  .result_inner_middle {
    margin: 20px 0;
  }
  .result_posted {
    flex-wrap: wrap;
  }
  .result_postd_iner {
    width: 100%;
    margin-bottom: 10px;
  }
  .rent_result_top {
    flex-wrap: wrap;
  }
  .rent_result_top h2 {
    margin-bottom: 25px;
    text-align: center;
    width: 100%;
  }
  .rent_resale {
    flex-wrap: wrap;
  }
  .rent_resale a {
    width: 100%;
    margin-bottom: 20px;
  }
  .select_box_rent .select_inner_box {
    font-size: 10px;
  }
  .dash_menu .dashmenu_ul li a {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .dashboard_right_panel {
    margin-top: 30px;
  }
}
