.add_acnt_view a{
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-weight: 400;
    background: #027994;
    border-radius: 5px;
    display: inline-block;
}

.add_acnt_view a span{
    color: #fff;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 400;
}

.chat_inner{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.chat_inner_left{
    max-width: 48%;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.chat_inner_right{
    max-width: 48%;
    width: 100%;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.recent_chat_partts{
    padding: 20px 30px 30px 30px;
}
.chat_input_field{
    position: relative;
}
.chat_input_field input[type="text"]{
    background: #FFFFFF;
    border: 1px solid #D2D2D2;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    height: 40px;
}
.chat_input_field input[type="text"]{
    box-shadow: none;
    outline: 0px;
}

.chat_search_but{
    background: #027994;
    border-radius: 0px 5px 5px 0px;
    height: 40px;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 20px;
    display: inline-block;
    color: #fff;
}

.chat_input_field .marg_0{
    margin-bottom: 0px !important;
}
.call_contacts{
    margin-top: 30px;
}
.call_contacts ul{
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
}
.call_contacts ul li{
    list-style: none;
    margin-right: 30px;
}
.call_contacts ul li a{
    font-weight: 500;
    font-size: 14px;
    color: #027994;
    text-transform: uppercase;
}

.inner_name{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #C5C5C5;
    border-bottom: 1px solid #C5C5C5;
    padding: 14px 10px;
    margin-top: -1px;
}

.user_imageert{
    width: 36px;
    height: 36px;
    border-radius: 100%;
    position: relative;
}
.name_of_person{
    margin-top: 30px;
}
.user_imageert img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.inner_name_of_person{
    max-width: 65%;
    width: 100%;
}

.inner_name_of_person h3{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.inner_name_of_person p{
    font-size: 12px;
    line-height: 14px;
    color: #707070;
    padding-top: 6px;
}

.inner_name_date p{
    font-size: 12px;
    line-height: 14px;
    color: #707070;
    
}

.img_count{
    background: #D84D4D;
    border: 1px solid #FFFFFF;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: block;
    text-align: center;
    line-height: 16px;
    color: #fff;
    font-weight: 500;
    font-size: 10px;
    position: absolute;
    top: -4px;
    left: -4px;
}

.inner_name.active, .inner_name:hover{
    background: #dcecf0;
}

.name_of_person{
    height: 400px;
    overflow-y: scroll;
}

.chat_inner_right{
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.chat_inner_top_partts{
    padding: 20px;
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    background: #fff;
    width: 100%;
    z-index: 9;
}

.online_person_left{
    display: flex;
    max-width: 50%;
    width: 100%;
    align-items: center;
}
.chat_inner_right{
    position: relative;
}
.online_person_right{
    display: flex;
    max-width: 50%;
    width: 100%;
}

.online_person_image, .inner_p_chat_left{
    width: 36px;
    height: 36px;
    background: #C4C4C4;
    border-radius: 100%;
}

.online_person_image img, .inner_p_chat_left img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.online_person_text{
    max-width: 70%;
    width: 100%;
    margin-left: 15px;
}
.online_person_text h3{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000;
}
.online_person_text p{
    font-size: 10px;
    line-height: 12px;
    color: #707070;
}
.online_person_text p span{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #1AD39B;
    display: inline-block;
    margin-right: 7px;
}
.online_person_text p{
    margin-top: 6px;
}

.online_person_right ul{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.online_person_right ul li{
    list-style: none;
    margin-left:20px;
}

.person_chat{
    margin-top: 6px;
    margin-bottom: 20px;
}
.days_ago{
    display: flex;
    justify-content: center;
    align-items: center;
}
.days_ago span{
    height: 20px;
    background: #F4F2D7;
border-radius: 5px;
    padding: 0 10px;
    display: inline-block;
    text-align: center;
    color: #015063;
    font-size: 10px;
    line-height: 20px;
    color: #015063;
    text-transform: uppercase;
}
.inner_p_chat_side{
    display: flex;
}
.inner_p_chat_right{
    max-width: 85%;
    width: 100%;
    margin-left: 10px;
}
.inner_p_chat_right_text{
    position: relative;
    background: #F4F2D7;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 28px;
    color: #000000;
    position: relative;
}
.inner_p_chat_right_text:before{
    position: absolute;
    content: "";
    width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent; 
  border-right:10px solid #F4F2D7;
  top: 4px;
  left: -10px;
}

.inner_person_chat{
    padding: 20px 10px;
}

.inner_p_chat_time span{
    font-size: 12px;
    color: #7E7E7E;
    line-height: 24px;
    margin-top: 6px;
    margin-left: 20px;
}

.inner_p_chat_other_side{
    display: flex;
    flex-wrap: wrap;
}

.inner_p_chat_other_side .inner_p_chat_left{}
.inner_p_chat_other_side .inner_p_chat_right .inner_p_chat_right_text{
    background: #027994;
    border-radius: 5px;
    padding: 5px 10px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 28px;
    position: relative;
}
.inner_p_chat_other_side .inner_p_chat_right .inner_p_chat_right_text:before{
    position: absolute;
    content: "";
    width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 0px; 
  border-left:10px solid #027994;
  top: 4px;
  left: auto;
  right: -10px;
}
.inner_p_chat_other_side .inner_p_chat_right{
    margin-right: 10px;
    margin-left: auto;
}
.inner_p_chat_right_text.text11q2{
    margin-top: 10px;
}
.inner_p_chat_right_text.text11q2:before{
    content: none !important;
    
}
.inner_p_chat_other_side{
    margin-top: 20px;
}
.inner_p_chat_other_side .inner_p_chat_time span{
    text-align: right;
    display: flex;
    justify-content: flex-end;

}

.inner_p_chat_right_text.riext1213q{
    margin-top: 10px;
}

.inner_p_chat_right_text.riext1213q:before{
    content: none !important;
}

.pers_chat_box{
    height: 400px;
    margin-top: 70px;
    overflow-y: scroll;
}

.type_your_mesage_box{
    border-top: 1px solid #C4C4C4;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex_button.flex1{
    position: relative;
    bottom: auto;
    top: 0;
    right: auto;
    width: auto;
    height: auto;
}

.flex_button.flex1 .custom_file{
    position: absolute;
    left: 0;
    right: auto;
    width: auto;
    height: auto;
}
.flex_button.flex1 .custom-file-input{
    width: 14px;
    height: 20px;
}
.flex_button.flex1 .file_upload_button{
    position: static;
    width: auto;
    height: auto;
    border: none;
    padding: 0px;
    box-shadow: none;
}
.message_left_icon ul{
    display: flex;
    align-items: center;
}
.message_left_icon ul li{
    list-style: none;
    margin-right: 12px;
}

.message_box_inner{
    max-width: 70%;
    width: 100%;
    position: relative;
}

.mtext_box{
    background: #FFFFFF;
    border: 1px solid #EFEFEF;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    padding: 0 40px 0 10px;
}
.mtext_box:focus{
    outline: 0px;
    box-shadow: none;
}
.mtext_box::placeholder{
    font-size: 14px;
    line-height: 18px;
    color: #2C2C2C;
}

.send_m_but{
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
@media screen and (max-width:991px){
    .chat_inner{
        flex-wrap: wrap;
    }
    .chat_inner_left, .chat_inner_right{
        max-width: 100%;
    }
    .chat_inner_left{
        margin-bottom: 30px;
    }
    .inner_name_of_person{
        max-width: 58%;
    }
    .online_person_text{
        max-width: 55%;
    }
    .inner_p_chat_right{
        max-width: 75%;
    }
    .inner_p_chat_right_text{
        font-size: 12px;
        line-height: 20px;
    }
    .inner_p_chat_other_side .inner_p_chat_right .inner_p_chat_right_text{
        font-size: 12px;
        line-height: 20px;
    }
    .online_person_text h3{
        font-size: 12px;
    }
    .online_person_right ul li{
        margin-left: 8px;
    }
    .pers_chat_box{
        margin-top: 100px;
    }
    .message_left_icon ul li{
        margin-right: 5px;
    }
    .call_contacts ul li a{
        font-size: 12px;
    }
    .call_contacts ul li{
        margin-right: 8px;
    }
}