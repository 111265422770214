.finance_inner_part{
    padding: 50px 0;
    /* background: #fff !important; */
}

.finance_box h2{
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    /* text-align: center; */
    text-transform: uppercase;
    color: #232731;
    margin-bottom: 30px;
}

.finance_box p{
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #3B3D39;
}


.finance_inner_box{
    padding: 50px 100px;
    background: #FFFFFF;
    /* box-shadow: 8px 9px 34px rgba(25, 30, 32, 0.06); */
    border-radius: 15px;
}

.finance_box_partts{
    display: flex;
    align-items: center;
}

.finance_box_partts_left{
    max-width: 40%;
    width: 100%;
}

.finance_box_partts_right{
    max-width: 60%;
    width: 100%;
    padding-left: 20px;
}

.finance_box_partts label{
    font-weight: 500;
    font-size: 14px;
    color: #4E5E63;
    margin-bottom: 0px;
}


.eqp_check input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .eqp_check label {
    position: relative;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    color: #475C63;
    font-family: 'Roboto', sans-serif !important;
  }
  
  .eqp_check label:before {
    content:'';
    -webkit-appearance: none;
    background: #EEF4F7;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  .eqp_check input:checked + label:before{
    background: #0ACDFA;
  }
  .eqp_check input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 7px;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

  .eqp_check{
    margin-bottom: 0px;
    margin-right: 25px;
  }

  .select_brand{
    height: 46px;
    border-radius: 10px;
    width: 100%;
    appearance: none;
    background: #EEF4F6 url(../../images/select_drop_down.png) no-repeat 98% center;
    border: 0px;
  }





  .eqp_check [type="radio"]:checked,
  .eqp_check [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.eqp_check [type="radio"]:checked + label,
.eqp_check [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    display: inline-block;
    color: #475C63;
    font-weight: 400;
    font-size: 16px;
}
.eqp_check [type="radio"]:checked + label:before,
.eqp_check [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #C5D7DD;
    border-radius: 48px;
    background: #fff;
}
.eqp_check [type="radio"]:checked + label:before{
    border-color: #0ACDFA;
}
.eqp_check [type="radio"]:checked + label:after,
.eqp_check [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 14px;
    height: 14px;
    background: #0ACDFA;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.eqp_check [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.eqp_check [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}




/* .radio-toolbar {
    margin: 10px;
  } */
  
  .radio-toolbar input[type="radio"] {
    opacity: 0;
    /* position: fixed; */
    width: 0;
  }
  .radio-toolbar{
    margin: 0 -4px;
  }
  .radio-toolbar label {
      display: inline-block;
      background: #EEF4F6;
      border: 2px solid transparent;
      border-radius: 4px;
      padding: 0 11px;
      height: 41px;
      line-height: 41px;
      margin: 0 4px;
      font-weight: 400;
      font-size: 16px;
      color: #475C63;
      cursor: pointer;
  }
  
  /* .radio-toolbar label:hover {
    background-color: #dfd;
  } */
  
  .radio-toolbar input[type="radio"]:focus + label {
      border: 2px solid #0ACDFA;
  }
  
  .radio-toolbar input[type="radio"]:checked + label {
    border: 2px solid #0ACDFA;
  }

  .finance_box_partts_right1{
    padding: 0 0px 0 10px;
  }

  .finance_box_partts {
    margin-bottom: 35px;
  }

  .eqp_check {
    margin-bottom: 10px;
  }


  .submit_inner{
    text-align: center;
  }

  .submit_inner .finance_submit_but{
    background: #FEBB0E;
    border-radius: 5px; 
    height: 48px;
    padding: 0 60px;
    font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  color: #fff;
  text-transform: uppercase;
  border: 0px;
  }


@media screen and (max-width:1199px){
  .finance_inner_box{
    padding: 20px 30px;
  }
}

@media screen and (max-width:767px){
  .finance_box{
    margin-top: 30px;
  }
  .finance_box_partts {
    flex-wrap: wrap;
  }

  .finance_box_partts_left, .finance_box_partts_right{
    max-width: 100%;
    padding: 0 0;
  }
  .radio-toolbar label{
    margin-bottom: 20px;
  }
}